module.exports = () ->
  (t) ->
    ww = Math.floor(t / 604800)
    dd = Math.floor(t / 86400) % 7
    hh = Math.floor(t / 3600) % 24
    mm = Math.floor(t / 60) % 60
    ss = t % 60

    result = []
    if ww > 0
      result.push("#{ww} weeks")
    if dd > 0
      result.push("#{dd} days")
    if hh > 0
      result.push("#{hh} hours")
    if mm > 0
      result.push("#{mm} minutes")
    if ss > 0
      result.push("#{ss} seconds")
    result.join(' ')
