angular = require('angular')

module.exports =
  angular.module('App.Components.Services', [])
  .controller('ServicesDuplicateCtrl', require('./services_duplicate/services-duplicate.ctrl'))

  .component('servicesCategories', require('./categories'))
  .component('servicesList', require('./list'))
  .component('serviceSave', require('./save'))
  .component('serviceForm', require('./form'))
  .component('serviceStylists', require('./stylists'))
