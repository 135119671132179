angular = require('angular')

module.exports =
  angular
  .module('App.Availability', [])

  .component('availabilityContainer', require('./containers/availability_container/availability-container'))

  .component('availabilityForm', require('./components/availability_form/availability-form'))
  .component('availabilityTimes', require('./components/availability_times/availability-times'))

  .config ($stateProvider) ->
    'ngInject'

    $stateProvider
      .state 'salons.edit.availability',
        url: '/availability'
        component: 'availabilityContainer'
        data:
          loginRequired: true
