_ = require('lodash')

module.exports = class ProductsContainerCtrl
  constructor: (RESTService, PromptService, ToastService, $state, ProductsService) ->
    'ngInject'
    @$state = $state
    @ProductsService = ProductsService
    @PromptService = PromptService
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @query =
      q: @$state.params?.q || ''
      order: @$state.params?.order || "-products.created_at"
      per_page: @$state.params?.per_page || 25
      page: @$state.params?.page || 1
      rows_array: [15, 25, 50]

    @fetchProducts().then => @loaded = true

  deleteProduct: (product) ->
    debugger
    @PromptService
      .show(title: "Do you realy want to delete product #{product.name.en}?")
      .then -> product.remove()
      .then =>
        _.remove(@products, id: product.id)
        @ToastService.success('Product successfully deleted!')
      .catch (e) =>
        @ToastService.error(e.data.error)

  fetchProducts: ->
    @$state.go('.', @query)

    @ProductsService
      .getList(@query)
      .then (response) =>
        @products = response.data
        @total = response.headers('x-total')
