module.exports = class ClientsListContainerCtrl
  constructor: ($state, ClientsService) ->
    'ngInject'
    @$state = $state
    @ClientsService = ClientsService

  $onInit: ->
    @ClientsService = @ClientsService.service(@salon.id)
    @loaded = false
    @query =
      order: @$state.params?.order || '-created_at'
      per_page: @$state.params?.per_page || 15
      rows_array: [15, 25, 50]
      page: @$state.params?.page || 1
      q: @$state.params?.q || ''
    @fetchClients()

  fetchClients: ->
    @$state.go('.', order: @query.order, per_page: @query.per_page, page: @query.page, q: @query.q)
    @ClientsService
      .getList('', @query)
      .then (response) =>
        @clients = response.data
        @total = response.headers('x-total')
        @loaded = true
