export PERSISTED_ADDRESS_ATTRIBUTES = Object.freeze(['country', 'city', 'area'])
export HOME_TYPES = Object.freeze([0, 2, 3])
export IN_SALON_TYPES = Object.freeze([0, 1])
export APPOINTMENT_STATES_ALLOWED_TO_CANCEL = Object.freeze(['paid', 'completed', 'waiting_for_cash_payment'])
export APPOINTMENT_DEFAULT_FILTER_STATES = Object.freeze(['paid', 'completed'])
export RESPONSIBILITY_SIDES = Object.freeze(['salony', 'owner'])
export APPOINTMENT_REFUND_TYPES = Object.freeze({
  global_credit: 'Global Credit',
  salon_credit: 'Salon Credit',
  cash: 'Cash'
})
export PAYMENT_DEFAULT_FILTER_STATES = Object.freeze(['finished', 'proceeded', 'failed_without_refund', 'failed'])
export ROLES = Object.freeze(['common', 'admin'])
export SERVER_DATE_FORMAT = 'YYYY-MM-DD'
export SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export SALON_TYPES = Object.freeze({
  'Default': 0
  'Standing': 1
  'Home only': 2
  'Make Up Artist': 3
})
export TOAST_NOTIFICATION = Object.freeze({
  updated_successfully: 'Update was successfully!'
  deleted_successfully: 'Delete was successfully!'
  created_successfully: 'Create was successfully!'
})
