module.exports = class CurrenciesNewContainerCtrl
  constructor: (ToastService, $state, CurrenciesService) ->
    'ngInject'
    @CurrenciesService = CurrenciesService
    @ToastService = ToastService
    @$state = $state

  createCurrency: (currency) ->
    @CurrenciesService.post(currency)
      .then =>
        @ToastService.success('Currency successfully created!')
        @$state.go('^.list')
      .catch (e) =>
        @ToastService.error(e.data.error)
