TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class NewSalonCtrl
  constructor: ($state, SalonService, $scope, ToastService, SalonTypesService) ->
    'ngInject'
    @$state = $state
    @SalonService = SalonService
    @ToastService = ToastService
    @salons = @SalonService.getList().$object
    @form = { shopping_cart_lifetime: 24 * 60 }
    @salonTypesDefinition = SalonTypesService(@form)

  inSalon: ->
    @salonTypesDefinition.isDefault() || @salonTypesDefinition.inSalonOnly()

  inHome: ->
    @salonTypesDefinition.isDefault() || @salonTypesDefinition.inHomeOnly()

  submit: (salon) ->
    delete salon.address if @inHome()
    @SalonService.post(salon)
      .then (response) =>
        @ToastService.success(TOAST_NOTIFICATION.created_successfully)
        @$state.go('salons.edit', { salonId: response.data.id})
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)
