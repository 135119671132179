module.exports = class ProvincesEditContainerCtrl
  constructor: (ProvincesService, $state, ToastService) ->
    'ngInject'
    @ProvincesService = ProvincesService
    @ToastService = ToastService
    @$state = $state

  $onInit: ->
    @provincesService = @ProvincesService
                       .service(@$state.params.countryId, @$state.params.cityId)
                       .one(@$state.params.provinceId)

  updateProvince: (province) ->
    @provincesService.customPUT(province)
      .then (response) =>
        @ToastService.success('Province successfully updated!')
        @province = response.data
      .catch (response) =>
        @ToastService.error(response.data.error)
