angular = require('angular')

module.exports =
  angular.module('App.Cities', [])
    .factory 'CitiesService', require('./services/cities.service')
    .factory 'TimeZonesService', require('./services/time-zones.service')
    # Container
    .component 'citiesListContainer', require('./containers/cities_list_container/cities-list-container')
    .component 'citiesEditContainer', require('./containers/cities_edit_container/cities-edit-container')
    .component 'citiesNewContainer', require('./containers/cities_new_container/cities-new-container')

    # UI components
    .component 'citiesList', require('./components/cities_list/cities-list')
    .component 'citiesForm', require('./components/cities_form/cities-form')

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'countries.edit.cities',
          abstract: true
          url: '/cities'
          views : {
            '@': {
              template: '<ui-view/>'
            },
          }
          data:
            loginRequired: true
        .state 'countries.edit.cities.list',
          url: '?{order}'
          component: 'citiesListContainer'
          ncyBreadcrumb:
            label: 'Cities'
          params:
            order: { dynamic: true, squash: true, value: null, type: 'string' }
        .state 'countries.edit.cities.new',
          url: '/new'
          component: 'citiesNewContainer'
          ncyBreadcrumb:
            label: 'New City'
            parent: 'countries.edit.cities.list'
        .state 'countries.edit.cities.edit',
          url: '/:cityId'
          component: 'citiesEditContainer'
          resolve:
            city: (CitiesService, $stateParams) ->
              'ngInject'
              CitiesService
                .service($stateParams.countryId)
                .one($stateParams.cityId)
                .get()
                .then (response) ->
                  response.data
          ncyBreadcrumb:
            label: '{{$$childHead.$ctrl.city.name.en}}'
            parent: 'countries.edit.cities.list'
