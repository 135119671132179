module.exports = class CategoriesNewContainerCtrl
  constructor: (RESTService, ToastService, $state, FormDataSerializerService) ->
    'ngInject'
    @RESTService = RESTService
    @ToastService = ToastService
    @$state = $state
    @FormDataSerializerService = FormDataSerializerService

  createCategory: (category) ->
    @RESTService
      .one('admin/categories')
      .withHttpConfig(transformRequest: angular.identity)
      .customPOST(@FormDataSerializerService(category), '', undefined, {'Content-Type': undefined})
      .then =>
        @ToastService.success('Category successfully created!')
        @$state.go('^.list')
      .catch (e) =>
        @ToastService.error(e.data.error)
