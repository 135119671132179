angular = require('angular')
require('./index.scss')

module.exports = ($state, $timeout) ->
  'ngInject'
  id = 0
  restrict: 'E'
  transclude: true
  scope:
    label: '@'
    state: '@'
    activateState: '@?'
    warning: '<?'
  template: require('./index.pug')()
  link: (scope, element, attrs, ctrl, transclude) ->
    checkState = scope.activateState || scope.state
    scope.tabId = "state-tab-#{id++}"
    scope.isActiveTab = ->
      $state.includes(checkState)
    $timeout ->
      angular.element(document.getElementById(scope.tabId).parentElement).on 'click', ->
        scope.$applyAsync -> $state.go(scope.state)

    transclude scope.$parent, (clone, scope) -> element.append(clone)

