angular = require('angular')

module.exports = class ShowCtrl
  constructor: (NotificationsCampaignService, $state) ->
    'ngInject'
    @campaign = NotificationsCampaignService.one($state.params.id).get().$object

  toJson: (obj) ->
    angular.toJson(obj, true)

