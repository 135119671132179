require('./promo-codes-list.scss')

module.exports = {
  template: require('./promo-codes-list.pug')()
  controller: require('./promo-codes-list.ctrl')
  bindings:
    promoCodes: '<'
    total: '<'
    query: '<'
    onPromoCodeDelete: '&'
    onRefresh: '&'
}
