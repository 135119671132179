_f = require('lodash/fp')

module.exports = class CountriesFormCtrl
  constructor: ($q, $scope, RESTService, GetMatchesService) ->
    'ngInject'
    @RESTService = RESTService
    @$q = $q
    @$scope = $scope
    @getMatchesService = GetMatchesService

  $onInit: ->
    @showAddressRawResponse = false
    @$q.all([
      @$$fetchCurrencyCodes(),
      @$$fetchISOCodes(),
      @$$fetchGateways()
    ]).then =>
      @country.currency_code ||= @currencyCodes[0]
      @loaded = true
    @mapClick = @mapClick.bind(@)

    @map =
      center:
        latitude: 29.375859
        longitude: 47.9774052
      zoom: 10
      control: {}
    @options =
      scrollwheel: false

    @map.center.latitude = @country.address_example_lat if @country.address_example_lat
    @map.center.longitude = @country.address_example_lng if @country.address_example_lng
    @marker =
      latitude: @country.address_example_lat
      longitude: @country.address_example_lng

  mapClick: (maps, event, mouseEvent) ->
    @$scope.$applyAsync =>
      coords = mouseEvent[0].latLng
      @marker.latitude = coords.lat()
      @marker.longitude = coords.lng()
      @country.address_example_lat = coords.lat()
      @country.address_example_lng = coords.lng()

  $$fetchCurrencyCodes: ->
    @RESTService
      .one('admin/currency_codes')
      .get()
      .then (response) =>
        @currencyCodes = response.data.currency_codes

  $$fetchISOCodes: ->
    @RESTService
      .one('admin/iso_codes')
      .get()
      .then (response) =>
        @isoCodes = response.data.iso_codes

  $$fetchGateways: ->
    @RESTService
      .one('admin/gateways')
      .get()
      .then (response) =>
        @gateways = _f.map('code')(response.data)

  matchedGateways: (query) ->
    @getMatchesService.call(_f.difference(@gateways, @country.gateways), query, '')

  matchedAddressFields: (query) ->
    @getMatchesService.call(_f.difference(@addressFields(), @country.address_preview_fields), query, '')

  addressFields: ->
    Object.keys(@country.address_schema)

  addressValueFor: (fieldName)->
    return unless @country.parsed_address_example
    @country.parsed_address_example[fieldName]?.name || @country.parsed_address_example.address_params?[fieldName]

  submit: ->
    @onSubmit(country: @country)
