module.exports = class SmsCampaignCtrl
  constructor: (RESTService, $state) ->
    'ngInject'

    @RESTService = RESTService
    @$state = $state

  $onInit: ->
    @$$fetchData().then => @loaded = true

  $$fetchData: ->
    @RESTService
      .one('admin/sms_campaigns', @$state.params.smsCampaignId)
      .get()
      .then (response) =>
        @smsCampaign = response.data
      .catch (response) ->

