_ = require('lodash')

module.exports = class ListCtrl
  constructor: ($state, SpecialOfferService, ToastService, PromptService) ->
    'ngInject'
    @PromptService = PromptService
    @ToastService = ToastService
    @service = SpecialOfferService($state.params.salonId)
    @specialOffers = @service.getList().$object

  delete: (specialOffer) ->
    @PromptService
      .show(title: "Are you sure you want to delete '#{specialOffer.name}' offer?")
      .then =>
        specialOffer
          .remove()
          .then =>
            @ToastService.success('Special offer was successfully deleted')
            _.remove @specialOffers, specialOffer
