require('./cities-form.scss')

module.exports = {
  template: require('./cities-form.pug')()
  controller: require('./cities-form.ctrl')
  bindings:
    city: '<'
    country: '<'
    onSubmit: '&'
}
