module.exports = class ProvincesListContainerCtrl
  constructor: (ProvincesService, $state, ToastService, PromptService) ->
    'ngInject'
    @ProvincesService = ProvincesService
    @$state = $state
    @ToastService = ToastService
    @PromptService = PromptService

  $onInit: ->
    @provincesService = @ProvincesService.service(@$state.params.countryId, @$state.params.cityId)
    @query =
      order: @$state.params?.order || "name"
    @fetchProvinces().then => @loaded = true

  deleteProvince: (province) ->
    @PromptService
      .show(title: "Do you realy want to delete province #{province.name}?")
      .then =>
        province.remove()
      .then =>
        _.remove(@provinces, id: province.id)
        @ToastService.success('Province successfully delete!')
      .catch (response) =>
        if response
          @ToastService.error(response.data.error)

  fetchProvinces: ->
    @$state.go('.', @query)

    @provincesService
      .getList(@query)
      .then (response) =>
        @provinces = response.data

