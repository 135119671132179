_ = require('lodash')

module.exports = class ProductBrandsContainerCtrl
  constructor: (RESTService, PromptService, ToastService, $state, ProductBrandsService) ->
    'ngInject'
    @$state = $state
    @ProductBrandsService = ProductBrandsService
    @PromptService = PromptService
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @query =
      q: @$state.params?.q || ''
      order: @$state.params?.order || "-product_brands.created_at"
      per_page: @$state.params?.per_page || 25
      page: @$state.params?.page || 1
      rows_array: [15, 25, 50]

    @fetchProductBrands().then => @loaded = true

  deleteProductBrand: (productBrand) ->
    debugger
    @PromptService
      .show(title: "Do you realy want to delete productBrand #{productBrand.name.en}?")
      .then -> productBrand.remove()
      .then =>
        _.remove(@productBrands, id: productBrand.id)
        @ToastService.success('ProductBrand successfully deleted!')
      .catch (e) =>
        @ToastService.error(e.data.error)

  fetchProductBrands: ->
    @$state.go('.', @query)

    @ProductBrandsService
      .getList(@query)
      .then (response) =>
        @productBrands = response.data
        @total = response.headers('x-total')
