angular = require('angular')

module.exports =
  angular.module('App.Gateways', [])
    # Container
    .component 'gatewaysListContainer', require('./containers/gateways_list_container/gateways-list-container')
    .component 'gatewaysEditContainer', require('./containers/gateways_edit_container/gateways-edit-container')

    # UI components
    .component 'gatewaysList', require('./components/gateways_list/gateways-list')
    .component 'gatewaysForm', require('./components/gateways_form/gateways-form')

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'gateways',
          abstract: true
          url: '/gateways'
          template: '<ui-view/>'
          data:
            loginRequired: true
        .state 'gateways.list',
          url: '?{order}'
          component: 'gatewaysListContainer'
          ncyBreadcrumb:
            label: 'Gateways'
          params:
            order: { dynamic: true, squash: true, value: null, type: 'string' }
        .state 'gateways.edit',
          url: '/:gatewayId'
          component: 'gatewaysEditContainer'
          ncyBreadcrumb:
            label: '{{$$childHead.$ctrl.gateway.name.en}}'
            parent: 'gateways.list'
