require('./areas-list.scss')

module.exports = {
  template: require('./areas-list.pug')()
  controller: require('./areas-list.ctrl')
  bindings:
    country: '<'
    query: '<'
    city: '<'
    areas: '<'
    onAreaDelete: '&'
    onRefresh: '&'
}
