module.exports = class CategoriesEditContainerCtrl
  constructor: ($state, RESTService, FormDataSerializerService, ToastService) ->
    'ngInject'
    @$state = $state
    @RESTService = RESTService
    @FormDataSerializerService = FormDataSerializerService
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @$$fetchCategory().then => @loaded = true

  updateCategory: (category) ->
    delete category.image unless category.image instanceof File
    category
      .withHttpConfig(transformRequest: angular.identity)
      .customPUT(@FormDataSerializerService(category), '', undefined, {'Content-Type': undefined})
      .then =>
        @ToastService.success('Category was successfully updated!')
        @$state.go('^.list')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  $$fetchCategory: ->
    @RESTService
      .one('admin/categories', @$state.params.categoryId)
      .get()
      .then (response) =>
        @category = response.data

