angular = require('angular')
module.exports = angular.module('App.settings', [])

.controller('App.settings.EditCtrl', require('./controllers/edit.ctrl'))

.factory('SettingsService', require('./services/settings.service'))

.config(($stateProvider) ->
  'ngInject'
  $stateProvider
    .state 'settings',
      url: '/settings'
      abstract: true
      template: '<ui-view></ui-view>'
      data:
        loginRequired: true
    .state 'settings.edit',
      controller: 'App.settings.EditCtrl as edit_ctrl'
      url: ''
      ncyBreadcrumb:
        label: 'Settings'
      template: require('./templates/edit.pug')()
      data:
        loginRequired: true
)
