module.exports = [
  '$mdToast',
  ($mdToast) ->
    error: (message) ->
      toast = $mdToast.simple().theme('error-toast')
      $mdToast.show(toast.content(message))
    success: (message) ->
      toast = $mdToast.simple().theme('success-toast')
      $mdToast.show(toast.content(message))
]
