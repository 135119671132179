module.exports = class GatewaysListContainerCtrl
  constructor: ($state, RESTService) ->
    'ngInject'
    @$state = $state
    @RESTService = RESTService

  $onInit: ->
    @query =
      order: @$state.params?.order || "gateways.name"
    @fetchGateways().then => @loaded = true

  fetchGateways: ->
    @$state.go('.', @query)

    @RESTService
      .service('admin/gateways')
      .getList(@query)
      .then (response) =>
        @gateways = response.data
