TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class UsersCreateContainerCtrl
  constructor: ($state, $window, ToastService, UsersService) ->
    'ngInject'
    @$state = $state
    @$window = $window
    @UsersService = UsersService
    @ToastService = ToastService
    @form =
      phone_country_code: '+965',
      role: 'common'

  submit: (form) ->
    @UsersService
      .post(form)
      .then (response) =>
        @ToastService.success(TOAST_NOTIFICATION.created_successfully)
        @$window.history.pushState({}, 'Users', 'users/')
        @$state.go('users.edit', userId: response.data.id)
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)
