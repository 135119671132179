module.exports = class PromoCodesNewContainerCtrl
  constructor: (RESTService, ToastService, $state) ->
    'ngInject'
    @RESTService = RESTService
    @ToastService = ToastService
    @$state = $state

  $onInit: ->
    @promoCode = {
      salon_ids: []
      country_ids: []
    }

  createPromoCode: (promoCode) ->
    @RESTService.service('admin/promo_codes')
      .post(promoCode)
      .then (response) =>
        @ToastService.success('Promo Code successfully created!')
        @$state.go('promo_codes.edit', promoCodeId: response.data.id)
      .catch (response) =>
        @ToastService.error(response.data.error)
