module.exports = class CountriesEditContainerCtrl
  constructor: (CountriesService, $state, ToastService) ->
    'ngInject'
    @CountriesService = CountriesService
    @ToastService = ToastService
    @$state = $state

  $onInit: ->
    @countriesService = @CountriesService.service().one(@$state.params.countryId)

  updateCountry: (country) ->
    country
      .put()
      .then (response) =>
        @ToastService.success('Country successfully updated!')
        @country = response.data
      .catch (response) =>
        @ToastService.error(response.data.error)
