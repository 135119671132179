moment = require('moment')

module.exports = class PaymentsCtrl
  constructor: ($state) ->
    'ngInject'

    @$state = $state

  $onInit: ->
    @from = @$state.params.from && moment(@$state.params.from, 'YYYY/MM/DD').toDate() || moment().startOf('month').toDate()
    @to = @$state.params.to && moment(@$state.params.to, 'YYYY/MM/DD').toDate() || moment().endOf('month').toDate()
    @fetchPayments()

  fetchPayments: ->
    @$state.go('.', from: @fromFormatted(), to: @toFormatted())
    @salon.one('payments').get(from: @fromFormatted(), to: @toFormatted())
      .then (response) =>
        @payments = response.data.payments
        @aggregations = response.data.aggregations
        @total = response.data.total

  fromFormatted: ->
    moment(@from).format('YYYY/MM/DD')

  toFormatted: ->
    moment(@to).format('YYYY/MM/DD')
