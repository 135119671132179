_ = require('lodash')
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class ProductsEditImagesContainerCtrl
  constructor: (ProductImagesService, ToastService, $state, RESTService, PromptService) ->
    'ngInject'
    @ToastService = ToastService
    @$state = $state
    @RESTService = RESTService
    @PromptService = PromptService
    @productImagesService = ProductImagesService(@$state.params.productId)

  $onInit: ->
    @images = []
    @path = @productImagesService.one().getRestangularUrl()
    @productImagesService.getList().then (response) =>
      @images = response.data

  onDndMove: ($index) ->
    @images.splice($index, 1)
    @productImagesService.one('sort').customPOST(ids: _.map(@images, (s) -> s.id))
      .then =>
        @ToastService.success('Images sorted successfully!')

  successFileUpload: (file, message, flow) ->
    @new_restangular_image = @RESTService.restangularizeElement(
      @salonService, JSON.parse(message), 'images')
    @images.push(@new_restangular_image)

  delete: (image) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete image?"
    ).then =>
      image.remove()
        .then =>
          _.remove(@images, image)
          @ToastService.success(TOAST_NOTIFICATION.deleted_successfully)
        .catch (response) =>
          message = response.data.error
          @ToastService.error(message)
