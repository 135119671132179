APPOINTMENT_DEFAULT_FILTER_STATES = require('app-constants').APPOINTMENT_DEFAULT_FILTER_STATES
_ = require('lodash')

module.exports = class AppointmentsListContainerCtrl
  constructor: ($q, $state, RESTService) ->
    'ngInject'
    @$state = $state
    @RESTService = RESTService
    @$q = $q

  $onInit: ->
    @loaded = false
    @appointmentService = @RESTService.setFullResponse(true).one('admin/appointments')
    @query =
      q: @$state.params?.q || ''
      user_id: @$state.params?.user_id
      order: @$state.params?.order || "-appointments.created_at"
      states: @statesFromParams()
      per_page: @$state.params?.per_page || 25
      page: @$state.params?.page || 1
      rows_array: [15, 25, 50]
    @$q.all([
      @fetchUser(),
      @fetchStates(),
      @fetchAppointments()
    ]).then => @loaded = true

  statesFromParams: ->
    if !@$state.params?.states
      APPOINTMENT_DEFAULT_FILTER_STATES
    else if @$state.params.states instanceof Array
      @$state.params.states
    else
      new Array(@$state.params.states)

  fetchUser: ->
    if @query.user_id
      @RESTService.one('admin/users', @query.user_id).get()
        .then (response) =>
          @user = response.data

  fetchStates: ->
    @appointmentService.one('existent_states')
      .get()
      .then (response) =>
        @states = response.data.states

  fetchAppointments: ->
    @$state.go('.', _.merge(@queryUrlParams(), states: @query.states))

    @appointmentService
      .getList(null, _.merge(@queryUrlParams(), 'states[]': @query.states))
      .then (response) =>
        @appointments = response.data
        @total = response.headers('x-total')

  queryUrlParams: ->
    order: @query.order,
    user_id: @query.user_id,
    per_page: @query.per_page,
    page: @query.page,
    q: @query.q
