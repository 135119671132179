ANCHOR_ID = "revert_prompt_anchor"

module.exports = ($compile, $rootScope, $sce, StatementRecordService, PromptService, ToastService) ->
  'ngInject'
  promptTemplate = \
    '<div>' +
      '<span>Are you sure you want to revert this record?</span>' +
      '<md-input-container class="md-block" flex>' +
        '<label>Reason (optional)</label>' +
        '<input name="reason" ng-model="dialogReason" type="text" />' +
    '</div>'


  appendDialogFormInput = ($scope)->
    angular.element(document.getElementById(ANCHOR_ID)).append($compile(promptTemplate)($scope))

  (record) ->
    $scope = $rootScope.$new(true)

    setTimeout(() => appendDialogFormInput($scope))

    PromptService.show(
      title: 'Confirm'
      htmlContent: $sce.trustAsHtml("<div id='#{ANCHOR_ID}'/>")
      ok: 'Yes'
      cancel: 'No'
    ).then =>
      StatementRecordService.one(record.id).remove(reason: $scope.dialogReason)
        .then =>
          ToastService.success('Record successfuly reverted!')
          return
        .catch (e) =>
          ToastService.error(e.data.error)

