module.exports = class NewCtrl
  constructor: ($state, SpecialOfferService, ToastService, ServiceService, FormDataSerializerService) ->
    'ngInject'
    @$state = $state
    @ToastService = ToastService
    @FormDataSerializerService = FormDataSerializerService

    @service = SpecialOfferService(@$state.params.salonId)
    @specialOffer =
      service_ids: []
    @services = ServiceService.service(@$state.params.salonId).getList().$object

  submit: ->
    @service.one()
      .withHttpConfig(transformRequest: angular.identity)
      .customPOST(@FormDataSerializerService(@specialOffer), '', undefined, {'Content-Type': undefined})
      .then =>
        @ToastService.success('Special offer was successfully created!')
        @$state.go('^.list')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)
