_ = require('lodash')
moment = require('moment')

module.exports = class AvailabilityContainerCtrl
  constructor: (RESTService, $httpParamSerializer) ->
    'ngInject'
    @RESTService = RESTService
    @$httpParamSerializer = $httpParamSerializer

  $onInit: ->
    @step = 1
    @loaded = false
    @$$fetchServices()
      .then => @loaded = true

  goToStepOne: ->
    @step = 1

  fetchAvailability: (query) ->
    @selection = _.reduce(query.services, (acc, addonIds, serviceId) =>
      service = _.find(@services, (s) -> s.id == parseInt(serviceId, 10))
      service = _.cloneDeep(service)
      service.service_addons = _.filter(service.service_addons, (addon) -> addonIds.includes(parseInt(addon.id, 10)))
      acc.services.push(service)
      acc
    , { date: moment(query.date).format('YYYY-MM-DD'), isHome: query.isHome, services: [] })

    params = _.reduce(query.services, (acc, arr, id) ->
      acc["services[#{id}]"] = if arr.length then arr else ''
      acc
    , { date: moment(query.date).format('YYYY-MM-DD'), is_home: query.isHome })
    @salon
      .one('availabilities')
      .customGET(null, params)
      .then (response) =>
        @step = 2
        @times = response.data.times

  $$fetchServices: ->
    @salon
      .getList('services')
      .then (response) =>
        @services = response.data
