require('./salon-form.scss')

module.exports = {
  template: require('./salon-form.pug')()
  controller: require('./salon-form.ctrl')
  bindings:
    salon: '='
    onSubmit: '&'
    onBranchioLinkUpdateButtonClick: '&'
}
