angular = require('angular')

module.exports = angular.module('App.feedbacks', [])

.controller('App.feedbacks.ListCtrl', require('./controllers/list.ctrl'))
.controller('App.feedbacks.ShowCtrl', require('./controllers/show.ctrl'))

.factory('FeedbackService', require('./services/feedback.service'))

.config(($stateProvider) ->
  'ngInject'
  $stateProvider
    .state 'feedbacks',
      abstract: true
      url: '/feedbacks'
      template: '<ui-view>'
      data:
        loginRequired: true
    .state 'feedbacks.list',
      url: '?{order}{per_page}{page}'
      params:
        order: { dynamic: true, squash: true, value: null, type: 'string' }
        per_page: { dynamic: true, squash: true, value: null, type: 'int' }
        page: { dynamic: true, squash: true, value: null, type: 'int' }
      controller: 'App.feedbacks.ListCtrl'
      controllerAs: 'list_ctrl'
      template: require('./templates/list.pug')()
      ncyBreadcrumb:
        label: 'Feedbacks'
    .state 'feedbacks.show',
      url: '/:id'
      controller: 'App.feedbacks.ShowCtrl'
      controllerAs: 'show_ctrl'
      template: require('./templates/show.pug')()
      ncyBreadcrumb:
        label: 'Feedback Info'
        parent: 'feedbacks.list'

)
