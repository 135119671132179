require('./index.scss')
module.exports = [
  '$rootScope',
  'RESTService',
  ($rootScope, RESTService) ->
    restrict: 'E'
    scope:
      control: '='
    template: require('./index.pug')()
    link: (scope) ->
      scope.session = $rootScope.session
      scope.isAdmin = RESTService.isAdminRole()
      scope.isCallCenterOperator = RESTService.isCallCenterOperatorRole()
]
