angular = require('angular')
_ = require('lodash')

HomeImage = require('images/salon-services-home.png')
InSalonImage = require('images/salon-services-salon.png')

module.exports = class ListCtrl
  constructor: ($state, ToastService, $mdDialog, PromptService, ServiceService) ->
    'ngInject'
    @ToastService = ToastService
    @$mdDialog = $mdDialog
    @PromptService = PromptService
    @$state = $state
    @serviceService = ServiceService.service(@$state.params.salonId)

  onDndMove: ($index) ->
    @services.splice($index, 1)
    @onSort()

  onDndInserted: (index, service) ->
    if service.category_id != @category.id
      @updateServiceCategory(service)

  typeImages: (service) ->
    res = []
    res.push(HomeImage) if service.is_home
    res.push(InSalonImage) if service.is_in_salon
    res

  delete: (service) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete service '#{service.name}'?"
    ).then =>
      service.remove()
        .then =>
          _.remove(@services, service)
          @ToastService.success('Service deleted successfully!')
        .catch (response) =>
          message = response.data.error
          @ToastService.error(message)

  updateServiceCategory: (service) ->
    @serviceService.one(service.id).put(category_id: @category.id)
      .then (response) =>
        service.category_id = @category.id
      .catch (response) =>
        @ToastService.error(response.data.error)

  duplicateService: (service) ->
    @$mdDialog.show(
      bindToController: true
      controller: 'ServicesDuplicateCtrl'
      template: require('../services_duplicate/services-duplicate.pug')()
      controllerAs: 'ctrl'
      locals: { service: service, salonId: @$state.params.salonId }
      clickOutsideToClose: true)
