angular = require('angular')

module.exports =
  angular
  .module('App.Products', [])

  .component('productsContainer', require('./containers/products_container/products-container'))
  .component('productsEditContainer', require('./containers/products_edit_container/products-edit-container'))
  .component('productsEditImagesContainer', require('./containers/products_edit_images_container/products-edit-images-container'))
  .component('productsNewContainer', require('./containers/products_new_container/products-new-container'))

  .component('productsList', require('./components/products_list/products-list'))
  .component('productsForm', require('./components/products_form/products-form'))

  .config ($stateProvider) ->
    'ngInject'

    $stateProvider
      .state 'products',
        url: '/products'
        abstract: true
        data:
          loginRequired: true
      .state 'products.list',
        url: '/?{order}{per_page}{page}{q}'
        component: 'productsContainer'
        ncyBreadcrumb:
          label: 'Products'
        params:
          q: { dynamic: true, squash: true, value: null, type: 'string' }
          order: { dynamic: true, squash: true, value: null, type: 'string' }
          per_page: { dynamic: true, squash: true, value: null, type: 'int' }
          page: { dynamic: true, squash: true, value: null, type: 'int' }

      .state 'products.new',
        url: '/new'
        component: 'productsNewContainer'
        ncyBreadcrumb:
          label: 'New Product'
          parent: 'products.list'
      .state 'products.edit',
        url: '/:productId'
        component: 'productsEditContainer'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.product.name.en}}'
          parent: 'products.list'
      .state 'products.edit.images',
        url: "/images"
        component: 'productsEditImagesContainer'
        ncyBreadcrumb:
          label: 'Images'
