module.exports = class ProductCategoriesEditContainerCtrl
  constructor: ($state, RESTService, FormDataSerializerService, ToastService) ->
    'ngInject'
    @$state = $state
    @RESTService = RESTService
    @FormDataSerializerService = FormDataSerializerService
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @$$fetchProductCategory().then => @loaded = true

  updateProductCategory: (productCategory) ->
    delete productCategory.image unless productCategory.image instanceof File
    productCategory
      .withHttpConfig(transformRequest: angular.identity)
      .customPUT(@FormDataSerializerService(productCategory), '', undefined, {'Content-Type': undefined})
      .then =>
        @ToastService.success('ProductCategory was successfully updated!')
        @$state.go('^.list')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  $$fetchProductCategory: ->
    @RESTService
      .one('admin/product_categories', @$state.params.productCategoryId)
      .get()
      .then (response) =>
        @productCategory = response.data

