_ = require('lodash')

module.exports = class StateFilterCtrl
  $onInit: ->
    @selectedStatesHash = _.reduce(@selectedStates, (acc, way) ->
      acc[way] = true
      acc
    , {})

  onStatesFilterChange: ->
    array = []
    _.forEach @selectedStatesHash, (value, state) ->
      array.push(state) if value
    @ngModel.$setViewValue(array)
    @onFilterChange()
