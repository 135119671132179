require('./activity-log.scss')

module.exports = {
  template: require('./activity-log.pug')()
  controller: require('./activity-log.ctrl')
  bindings:
    trackedObject: '<'
    salon: '<'
    stylist: '<'
}
