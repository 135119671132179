module.exports = class ProductBrandsNewContainerCtrl
  constructor: (RESTService, ToastService, $state, FormDataSerializerService) ->
    'ngInject'
    @RESTService = RESTService
    @ToastService = ToastService
    @$state = $state
    @FormDataSerializerService = FormDataSerializerService

  createProductBrand: (productBrand) ->
    @RESTService
      .one('admin/product_brands')
      .withHttpConfig(transformRequest: angular.identity)
      .customPOST(@FormDataSerializerService(productBrand), '', undefined, {'Content-Type': undefined})
      .then =>
        @ToastService.success('Product Brand successfully created!')
        @$state.go('^.list')
      .catch (e) =>
        @ToastService.error(e.data.error)
