_ = require('lodash')
moment = require('moment')

module.exports = class DateTimeInputCtrl
  constructor: (RoundTime) ->
    'ngInject'

    @RoundTime = RoundTime

  $onInit: ->
    @ngModel.$render = =>
      return unless @ngModel.$viewValue
      @dateModel = @RoundTime(@ngModel.$viewValue)
      @timeModel = @RoundTime(@ngModel.$viewValue)

  setDate: ->
    @timeModel = _.clone(@dateModel)
    @updateModel()

  updateModel: ->
    date = moment(@RoundTime(@dateModel)).format('YYYY-MM-DD')
    param = if @showTime()
      time = moment(@RoundTime(@timeModel)).format('HH:mm:ss')
      "#{date} #{time}"
    else
      date
    @ngModel.$setViewValue(param)

  showTime: ->
    _.defaultTo(@showTimeFlag, true)
