SALON_TYPES = require('app-constants').SALON_TYPES
_ = require('lodash')

module.exports = class PaymentSettingsCtrl
  $onInit: ->
    @loaded = false
    @salon.one('available_payment_ways').get()
      .then (response) =>
        @availablePaymentWays = response.data.available_payment_ways
        @enabledPaymentWays = _.reduce(@salon.allowed_payment_ways, (acc, way) ->
          acc[way] = true
          acc
        , {})
        @homeServiceEnabledPaymentWays = _.reduce(@salon.home_service_allowed_payment_ways, (acc, way) ->
          acc[way] = true
          acc
        , {})
        @loaded = true

  onAllowedPaymentWaysChanged: ->
    @salon.allowed_payment_ways = []
    _.forEach @enabledPaymentWays, (value, paymentWay) =>
      @salon.allowed_payment_ways.push(paymentWay) if value

  onHomeServiceAllowedPaymentWaysChanged: ->
    @salon.home_service_allowed_payment_ways = []
    _.forEach @homeServiceEnabledPaymentWays, (value, paymentWay) =>
      @salon.home_service_allowed_payment_ways.push(paymentWay) if value

  showHomePaymentWays: ->
    @salon.type == SALON_TYPES.Default

  submitSalon: ->
    @onSubmit(salon: @salon)
