module.exports = class ServiceFormController
  constructor: (CategoriesService, $scope, SalonTypesService) ->
    'ngInject'
    @CategoriesService = CategoriesService
    @SalonTypesService = SalonTypesService

  $onInit: ->
    @salonTypesDefinition = @SalonTypesService(@salon)
    @service.is_home      = @salonTypesDefinition.showHomeWorkingHours()
    @service.is_in_salon  = @salonTypesDefinition.showWorkingHours()
    @CategoriesService.getList()
      .then (response) =>
        @categories = response.data
