module.exports = class CountriesNewContainerCtrl
  constructor: (CountriesService, ToastService, $state) ->
    'ngInject'
    @CountriesService = CountriesService
    @ToastService = ToastService
    @$state = $state

  $onInit: ->
    @countriesService = @CountriesService.service()
    @country = {
      gateways: []
      address_mapping: {
        street_number: 'route',
        area: 'locality',
        block: 'neighborhood',
        province: 'administrative_area_level_1',
        city: 'country'
      }
      address_schema: {
        street_number: 'Street Number',
        area: 'Area',
        block: 'Block',
        province: 'Province',
        city: 'City',
        country: 'Country'
      }
    }

  createCountry: (country) ->
    @countriesService
      .post(country)
      .then (response) =>
        @ToastService.success('Country successfully created!')
        @$state.go('countries.edit', countryId: response.data.id)
      .catch (response) =>
        @ToastService.error(response.data.error)
