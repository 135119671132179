angular = require('angular')
_ = require('lodash')
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class ListCtrl
  constructor: ($state, StylistService, ToastService,  $mdDialog, PromptService) ->
    'ngInject'
    @$state = $state
    @StylistsService = StylistService(@$state.params.salonId)
    @ToastService = ToastService
    @$mdDialog = $mdDialog
    @PromptService = PromptService

    @stylists = @StylistsService.getList().$object

  update: (stylist) ->
    stylist.put()
      .then =>
        @ToastService.success(TOAST_NOTIFICATION.updated_successfully)
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  disable: (stylist) ->
    @StylistsService.one(stylist.id).one('disable').post()
      .then (response) =>
        @ToastService.success(TOAST_NOTIFICATION.updated_successfully)
        stylist.disabled_at = response.data.disabled_at
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  enable: (stylist) ->
    @StylistsService.one(stylist.id).one('enable').post()
      .then (response) =>
        @ToastService.success(TOAST_NOTIFICATION.updated_successfully)
        stylist.disabled_at = null
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  delete: (stylist) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete stylist '#{stylist.name}'?"
    ).then =>
      stylist.remove()
        .then =>
          _.remove(@stylists, stylist)
          @ToastService.success(TOAST_NOTIFICATION.deleted_successfully)
        .catch (response) =>
          message = response.data.error
          @ToastService.error(message)

  cancel: ->
    @showNewForm = false
