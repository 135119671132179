NAV_ID = 'navigation'

module.exports = class AppCtrl
  constructor: (RESTService, $mdMedia, $mdSidenav, $mdComponentRegistry) ->
    'ngInject'
    @RESTService = RESTService
    @$mdMedia = $mdMedia
    @$mdSidenav = $mdSidenav
    @$mdComponentRegistry = $mdComponentRegistry

  $onInit: ->
    @$mdComponentRegistry.when(NAV_ID).then =>
      sidenav = @$mdSidenav(NAV_ID)
      @sidebarClose = -> sidenav.close()
      @sidebarToogle = _.debounce((-> sidenav.toggle()), 200)
