_ = require('lodash')

HomeImage = require('images/salon-services-home.png')
InSalonImage = require('images/salon-services-salon.png')

module.exports = class ServicesCtrl
  constructor: ($q, $stateParams, ServiceService, ToastService, CategoriesService) ->
    'ngInject'
    @ToastService = ToastService
    @SalonServicesService = ServiceService.service($stateParams.salonId)
    @$q = $q
    @CategoriesService = CategoriesService

  $onInit: ->
    @loaded = false
    @ownedServices = {}
    _.forEach @stylist.service_ids, (id) => @ownedServices[id] = true

    @$q.all([
      @$$fetchCategories()
      @$$fetchServices()
    ]).then => @loaded = true

  $$fetchCategories: ->
    @CategoriesService.getList()
      .then (response) =>
        @categories = response.data

  $$fetchServices: ->
    @SalonServicesService.getList()
      .then (response) =>
        @services = _.groupBy(response.data, (service) -> service.category_id)

  categoryFor: (categoryId) ->
    _.find(@categories, id: parseInt(categoryId, 10))

  typeImages: (service) ->
    res = []
    res.push(HomeImage) if service.is_home
    res.push(InSalonImage) if service.is_in_salon
    res

  onDataChange: ->
    @stylist.service_ids = []
    _.forEach @ownedServices, (value, service_id) =>
      @stylist.service_ids.push(service_id) if value
