_ = require('lodash')
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class NewNotificationCtrl
  USER = 'user'
  ANDROID = 'android'
  IOS = 'ios'

  constructor: ($state, NotificationService, UsersService, ToastService, RESTService) ->
    'ngInject'

    @$state = $state
    @NotificationService = NotificationService
    @UsersService = UsersService
    @ToastService = ToastService
    @RESTService = RESTService
    @currentTemplate = $state.params.template
    @form = { message_type: USER }
    @$$fetchTemplates()
      .then =>
        @templateSelected() if @currentTemplate

  isUser: ->
    @form.message_type == USER

  isDevice: ->
    @form.message_type == ANDROID || @form.message_type == IOS

  querySearchUser: (query) ->
    @UsersService.getList(q: query)
      .then (response) =>
        @$$convertToUsersAutocomplete(response.data)

  templateSelected: ->
    template = _.find(@templates, (t) => t.data.type == @currentTemplate)
    return if _.isEmpty(template)
    @form.message = template.message
    @form.data = template.data

  submit: ->
    @form.user_id = @user?.value
    @NotificationService.post(@form)
      .then (response) =>
        @ToastService.success(TOAST_NOTIFICATION.created_successfully)
        @$state.go('notifications.list')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  $$fetchTemplates: ->
    @RESTService
      .one('admin/notification_templates')
      .getList()
      .then (response) =>
        @templates = response.data
        @templates.push(data: { type: 'CUSTOM' }, name: 'Custom')

  $$convertToUsersAutocomplete: (users) ->
    _.map users, (user) ->
      value: user.id
      display: "#{user.first_name} #{user.last_name}(#{user.phone_number})"

