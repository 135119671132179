angular = require('angular')

module.exports =
  angular
  .module('App.Categories', [])

  .component('categoriesContainer', require('./containers/categories_container/categories-container'))
  .component('categoriesEditContainer', require('./containers/categories_edit_container/categories-edit-container'))
  .component('categoriesNewContainer', require('./containers/categories_new_container/categories-new-container'))

  .component('categoriesList', require('./components/categories_list/categories-list'))
  .component('categoriesForm', require('./components/categories_form/categories-form'))

  .config ($stateProvider) ->
    'ngInject'

    $stateProvider
      .state 'categories',
        url: '/categories'
        abstract: true
        data:
          loginRequired: true
      .state 'categories.list',
        url: '/'
        component: 'categoriesContainer'
        ncyBreadcrumb:
          label: 'Categories'
      .state 'categories.new',
        url: '/new'
        component: 'categoriesNewContainer'
        ncyBreadcrumb:
          label: 'New Category'
          parent: 'categories.list'
      .state 'categories.edit',
        url: '/:categoryId'
        component: 'categoriesEditContainer'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.category.name.en}}'
          parent: 'categories.list'
