module.exports = class OwnerRegistrationRequestsContainerCtrl
  constructor: ($state, RESTService, ToastService) ->
    'ngInject'
    @RESTService = RESTService
    @ToastService = ToastService
    @$state = $state

  $onInit: ->
    @RegistrationRequests = @RESTService.setFullResponse(true).service('admin/owner/registration_requests')
    @loaded = false
    @query =
      order: @$state.params?.order || ''
      per_page: @$state.params?.per_page || 15
      rows_array: [15, 25, 50]
      page: @$state.params?.page || 1
      q: @$state.params?.q || ''
    @fetchRegistrationRequests()
      .then =>
        @loaded = true

  fetchRegistrationRequests: ->
    @$state.go('.', order: @query.order, per_page: @query.per_page, page: @query.page, q: @query.q)
    @RegistrationRequests
      .getList(@query)
      .then (response) =>
        @registrationRequests = response.data
        @total = response.headers('x-total')

  mark: (registrationRequest) ->
    registrationRequest
      .post('mark_completed')
      .then (response) =>
        _.merge(registrationRequest, response.data)
        @ToastService.success('Registration request marked as completed!')

