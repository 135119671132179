module.exports = class PromoCodesListContainerCtrl
  constructor: ($state, ToastService, PromptService, RESTService) ->
    'ngInject'
    @RESTService = RESTService
    @$state = $state
    @ToastService = ToastService
    @PromptService = PromptService

  $onInit: ->
    @promoCodesService = @RESTService.service('admin/promo_codes')
    @loaded = false
    @query =
      q: @$state.params?.q || ''
      order: @$state.params?.order || "promo_codes.name"
    @fetchPromoCodes().then => @loaded = true

  deletePromoCode: (promoCode) ->
    @PromptService
      .show(title: "Do you realy want to delete Promo Code #{promoCode.name}?")
      .then =>
        promoCode.remove()
      .then =>
        _.remove(@promoCodes, id: promoCode.id)
        @ToastService.success('Promo Code successfully deleted!')
      .catch (response) =>
        if response
          @ToastService.error(response.data.error)

  fetchPromoCodes: ->
    @$state.go('.', @query)

    @promoCodesService
      .getList(@query)
      .then (response) =>
        @promoCodes = response.data
        @total = response.headers('x-total')
