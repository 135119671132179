_ = require('lodash')

module.exports = class AreasTableContainerCtrl
  constructor: (AreasService, $state, ToastService, PromptService) ->
    'ngInject'
    @AreasService = AreasService
    @$state = $state
    @ToastService = ToastService
    @PromptService = PromptService

  $onInit: ->
    @areasService = @AreasService.service(@$state.params.countryId, @$state.params.cityId)
    @query =
      order: @$state.params?.order || "name"
    @fetchAreas().then => @loaded = true

  deleteArea: (area) ->
    @PromptService
      .show(title: "Do you realy want to delete area #{area.name}?")
      .then =>
        area.remove()
      .then =>
        _.remove(@areas, id: area.id)
        @ToastService.success('Area successfully delete!')
      .catch (response) =>
        if response
          @ToastService.error(response.data.error)

  fetchAreas: ->
    @$state.go('.', @query)

    @areasService
      .getList(@query)
      .then (response) =>
        @areas = response.data

