_ = require('lodash')
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class UploadImageTabCtrl
  constructor: (SalonService, ToastService, $state, RESTService, PromptService) ->
    'ngInject'
    @ToastService = ToastService
    @$state = $state
    @RESTService = RESTService
    @PromptService = PromptService
    @salonService = SalonService.one(@$state.params.salonId)

  $onInit: ->
    salonId = @$state.params.salonId
    @path = "#{@RESTService.getBaseUrl()}/admin/salons/#{salonId}/images"
    @profile_path = "#{@RESTService.getBaseUrl()}/admin/salons/#{salonId}/profile_image"
    @salonService.get().then (response) =>
      @profile_image = response.data.profile_image_urls
    @user_token = @RESTService.token().user_auth_token
    @salonService.getList('images').then (response) =>
      @images = response.data

  onDndMove: ($index) ->
    @images.splice($index, 1)
    @images.all('sort').customPOST(ids: _.map(@images, (s) -> s.id))
      .then =>
        @ToastService.success('Images sorted successfully!')

  successFileUpload: (file, message, flow) ->
    @new_restangular_image = @RESTService.restangularizeElement(
      @salonService, JSON.parse(message), 'images')
    @images.push(@new_restangular_image)

  successProfileUpload: (file, message, flow) ->
    new_message = JSON.parse(message)
    @profile_image = new_message.profile_image_urls

  delete: (image) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete image?"
    ).then =>
      @salonService.one('images', image.id).remove()
        .then =>
          _.remove(@images, image)
          @ToastService.success(TOAST_NOTIFICATION.deleted_successfully)
        .catch (response) =>
          message = response.data.error
          @ToastService.error(message)
