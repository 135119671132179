angular = require('angular')

module.exports =
  angular
  .module('App.ProductBrands', [])

  .component('productBrandsContainer', require('./containers/product_brands_container/product-brands-container'))
  .component('productBrandsEditContainer', require('./containers/product_brands_edit_container/product-brands-edit-container'))
  .component('productBrandsNewContainer', require('./containers/product_brands_new_container/product-brands-new-container'))

  .component('productBrandsList', require('./components/product_brands_list/product-brands-list'))
  .component('productBrandsForm', require('./components/product_brands_form/product-brands-form'))

  .config ($stateProvider) ->
    'ngInject'

    $stateProvider
      .state 'product_brands',
        url: '/product_brands'
        abstract: true
        data:
          loginRequired: true
      .state 'product_brands.list',
        url: '/?{order}{per_page}{page}{q}'
        component: 'productBrandsContainer'
        ncyBreadcrumb:
          label: 'Product Brands'
        params:
          q: { dynamic: true, squash: true, value: null, type: 'string' }
          order: { dynamic: true, squash: true, value: null, type: 'string' }
          per_page: { dynamic: true, squash: true, value: null, type: 'int' }
          page: { dynamic: true, squash: true, value: null, type: 'int' }

      .state 'product_brands.new',
        url: '/new'
        component: 'productBrandsNewContainer'
        ncyBreadcrumb:
          label: 'New Product Brand'
          parent: 'product_brands.list'
      .state 'product_brands.edit',
        url: '/:productBrandId'
        component: 'productBrandsEditContainer'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.productBrand.name.en}}'
          parent: 'product_brands.list'
