angular = require('angular')

module.exports =
  angular.module('App.Provinces', [])
    .factory 'ProvincesService', require('./services/provinces.service')
    # Container
    .component 'provincesListContainer', require('./containers/provinces_list_container/provinces-list-container')
    .component 'provincesEditContainer', require('./containers/provinces_edit_container/provinces-edit-container')
    .component 'provincesNewContainer', require('./containers/provinces_new_container/provinces-new-container')

    # UI components
    .component 'provincesList', require('./components/provinces_list/provinces-list')
    .component 'provinceForm', require('./components/province_form/province-form')

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'countries.edit.cities.edit.provinces',
          url: '/provinces'
          abstract: true
          views : {
            '@': {
              template: '<ui-view/>'
            },
          }
        .state 'countries.edit.cities.edit.provinces.list',
          url: '?{order}'
          component: 'provincesListContainer'
          ncyBreadcrumb:
            label: 'Provinces'
          params:
            order: { dynamic: true, squash: true, value: null, type: 'string' }
        .state 'countries.edit.cities.edit.provinces.new',
          url: '/new'
          component: 'provincesNewContainer'
          ncyBreadcrumb:
            label: 'New Province'
            parent: 'countries.edit.cities.edit.provinces.list'
        .state 'countries.edit.cities.edit.provinces.edit',
          url: '/:provinceId'
          component: 'provincesEditContainer'
          resolve:
            province: (ProvincesService, $stateParams) ->
              'ngInject'
              ProvincesService
                .service($stateParams.countryId, $stateParams.cityId)
                .one($stateParams.provinceId)
                .get()
                .then (response) ->
                  response.data
          ncyBreadcrumb:
            label: '{{$$childHead.$ctrl.province.name.en}}'
            parent: 'countries.edit.cities.edit.provinces.list'
