_f = require('lodash/fp')

module.exports = ->
  {
    call: (items, text, searchAttr) ->
      textRegExp = new RegExp(_f.escapeRegExp(text), 'i')
      _f.filter(
        (item) ->
          searchString = if searchAttr.length
            _f.reduce((object, attr) -> object[attr])(item, _f.split('.', searchAttr))
          else
            item

          textRegExp.test(searchString)
      )(items)
  }
