_ = require('lodash')

module.exports = class EditCtrl
  constructor: (ClientVersionService, $state, ToastService) ->
    'ngInject'

    @$state = $state
    @ToastService = ToastService
    ClientVersionService.one(@$state.params.id).get()
      .then (response) =>
        @clientVersion = response.data

  submit: ->
    @clientVersion.put()
      .then =>
        @$state.go('client_versions.list')
        @ToastService.success('Client Versions successfully updated')
      .catch =>
        @ToastService.error('Client Versions is not updated')
