module.exports = class EditCtrl
  constructor: (stylistRespose, ToastService, salon, SalonTypesService) ->
    'ngInject'
    @ToastService = ToastService
    @stylist = stylistRespose.data
    @salon = salon
    @salonTypesDefinition = SalonTypesService(@salon, @stylist)

  submit: ->
    @stylist.put()
    .then =>
      @ToastService.success('Stylist successfully updated!')
    .catch (response) =>
      if response
        @ToastService.error(response.data.error)
