module.exports = class ShowPaymentCtrl
  constructor: ($state, PaymentService, UsersService, SalonService, ToastService) ->
    'ngInject'
    @$state = $state
    @PaymentService = PaymentService
    @UsersService = UsersService
    @SalonService = SalonService
    @ToastService = ToastService

    paymentService = @PaymentService.one(@$state.params.paymentId)
    paymentService.get().then (response) =>
      @payment = response.data

  show_user: (item) ->
    @UsersService.one(item)
      .get()
      .then (response) =>
        @$state.go('users.edit', { userId: item})
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)


  show_salon: (item) ->
    @SalonService.one(item)
      .get()
      .then (response) =>
        @$state.go('salons.edit', { salonId: item})
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)
