module.exports = class ShowNotificationCtrl
  constructor: (RESTService, $state) ->
    'ngInject'
    @loaded = false
    RESTService
      .one('/admin/notifications', $state.params.notificationId)
      .get()
      .then (response) =>
        @notification = response.data
        @json = JSON.parse(@notification.status.args)
        @loaded = true
