module.exports = class BulkTransfersListContainerCtrl
  constructor: ($state, BulkTransferService) ->
    'ngInject'
    @$state = $state
    @BulkTransferService = BulkTransferService

  $onInit: ->
    @loaded = false
    @query =
      rows_array: [15, 25, 50]
      per_page: @$state.params?.per_page || 15
      page: @$state.params?.page || 1
    @fetchBulkTransfers()

  fetchBulkTransfers: ->
    @$state.go('.', per_page: @query.per_page, page: @query.page)

    @BulkTransferService
      .getList(@query)
      .then (response) =>
        @bulk_transfers = response.data
        @total = response.headers('x-total')
        @loaded = true
