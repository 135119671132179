_ = require('lodash')

module.exports = [
  'RESTService'
  (RESTService) ->
    restrict: 'E'
    scope:
      profile: '='
      path:'='
      images: '='
      onSuccessUpload: '&?'
      notProfile: '='
    template: require('./index.pug')()
    link: (scope) ->
      scope.successFileUpload ||= _.noop
      scope.onSuccessUpload ||= _.noop
      token = RESTService.token()
      scope.headers =
        'X-User-Token': token.user_auth_token
]
