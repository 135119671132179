require('./provinces-list.scss')

module.exports = {
  template: require('./provinces-list.pug')()
  controller: require('./provinces-list.ctrl')
  bindings:
    country: '<'
    query: '<'
    city: '<'
    provinces: '<'
    onProvinceDelete: '&'
    onRefresh: '&'
}
