require('./payments-list.scss')

module.exports = {
  template: require('./payments-list.pug')()
  controller: require('./payments-list.ctrl')
  bindings:
    salon: '<'
    payments: '<'
    aggregations: '<'
    total: '<'
    from: '<'
    to: '<'
}
