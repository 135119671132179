_ = require('lodash')

module.exports = class AvailabilityFormCtrl
  constructor: ->
    'ngInject'

  $onInit: ->
    @serviceIds = {}
    @serviceAddonIds = {}

  submit: ->
    query = _.reduce(@serviceIds, (acc, added, serviceId) =>
      if added
        service = _.find(@services, (s) -> s.id == parseInt(serviceId, 10))
        allServiceAddonIds = _.map(service.service_addons, 'id')
        acc.services[serviceId] = _.filter(allServiceAddonIds, (id) => @serviceAddonIds[id])
      acc
    , { date: @date, isHome: @isHome, services: {} })
    @onSubmit(query: query)
