require('./products-form.scss')

module.exports = {
  template: require('./products-form.pug')()
  controller: require('./products-form.ctrl')
  bindings:
    product: '<'
    isNew: '<'
    onSubmit: '&'
}
