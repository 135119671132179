require('./salonsAutocomplete.scss')

module.exports = {
  template: require('./salonsAutocomplete.pug')()
  controller: require('./salonsAutocomplete.ctrl')
  require:
    ngModel: 'ngModel'
  bindings:
    ngRequired: '<'
}
