APPOINTMENT_STATES_ALLOWED_TO_CANCEL = require('app-constants').APPOINTMENT_STATES_ALLOWED_TO_CANCEL

module.exports = class AppointmentsListCtrl
  constructor: ($mdDialog) ->
    'ngInject'
    @$mdDialog = $mdDialog

  $onInit: ->
    @updateTable = @updateTable.bind(@)

  updateTable: ->
    @onRefresh()

  allowedToCancel: (appointment) ->
    APPOINTMENT_STATES_ALLOWED_TO_CANCEL.includes(appointment.state)

  cancel: (appointment) ->
    @$mdDialog.show(
      bindToController: true
      controller: 'AppointmentsCancelCtrl'
      template: require('../appointments_cancel/appointments-cancel.pug')()
      controllerAs: 'ctrl'
      locals: { appointment: appointment }
      clickOutsideToClose: true)
