module.exports = class AreaFormCtrl
  constructor: ($state, $scope, GetMatchesService, ProvincesService) ->
    'ngInject'
    @$scope = $scope
    @$state = $state
    @getMatchesService = GetMatchesService
    @ProvincesService = ProvincesService.service(@$state.params.countryId, @$state.params.cityId)

  $onInit: ->
    @$$fetchProvinces().then =>
      @province = _.find(@provinces, id: parseInt(@area.province_id))

      @map =
        center:
          latitude: 29.375859
          longitude: 47.9774052
        zoom: 10
        control: {}
      @options =
        scrollwheel: false

      @map.center.latitude = @area.lat if @area.lat
      @map.center.longitude = @area.lng if @area.lng
      @marker =
        latitude: @area.lat
        longitude: @area.lng
      @mapClick = @mapClick.bind(@)
      @loaded = true

  submit: ->
    @onSubmit(area: @area)

  setProvinceId: ->
    @area.province_id = @province?.id

  $$fetchProvinces: ->
    @ProvincesService
      .getList()
      .then (response) =>
        @provinces = response.data

  mapClick: (maps, event, mouseEvent) ->
    @$scope.$applyAsync =>
      coords = mouseEvent[0].latLng
      @marker.latitude = coords.lat()
      @marker.longitude = coords.lng()
      @area.lat = coords.lat()
      @area.lng = coords.lng()
