_f = require('lodash/fp')

module.exports = class CountryCodeSelect
  constructor: ($scope, CountryCodeService, GetMatchesService) ->
    'ngInject'
    @$scope = $scope
    @CountryCodeService = CountryCodeService
    @selectedItem = null
    @getMatchesService = GetMatchesService

  $onInit: ->
    @ngModel.$render = =>
      @$$parseModel()

    @CountryCodeService.get()
      .then (response) =>
        @items = _f.map((code) => _f.merge(code, display: "#{code.dial_code} #{code.name}"))(response.data.country_codes)
        @$$parseModel()
        if !@phoneCountryCode
          @phoneCountryCode = @items[0]
          @updateModel()

  $$parseModel: ->
    @phoneCountryCode = _f.find(dial_code: @ngModel.$viewValue)(@items) if !!@ngModel.$viewValue

  updateModel: ->
    @ngModel.$setViewValue(@phoneCountryCode?.dial_code)
