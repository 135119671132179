_ = require('lodash')

module.exports = class UsersAutocompleteCtrl
  constructor: (UsersService, $timeout) ->
    'ngInject'
    @UsersService = UsersService
    @$timeout = $timeout

  querySearchUser: () ->
    @UsersService.getList(q: @query)
      .then (response) =>
        @$$convertToUserAutocomplete(response.data)

  $onInit: ->
    @$timeout =>
      @currentUser = {
        value: @ngModel.$viewValue,
        display: @$$displayUser(@user)
      } if @ngModel.$viewValue

  onChange: ->
    @ngModel.$setViewValue(@currentUser?.value)

  $$convertToUserAutocomplete: (users) ->
    _.map users, (user) =>
      value: user.id
      display: @$$displayUser(user)

  $$displayUser: (user) ->
    _.compact([user.first_name, user.last_name, "(#{user.phone_country_code}#{user.phone_number})"]).join(' ')
