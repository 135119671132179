module.exports = class ProductsEditContainerCtrl
  constructor: ($state, RESTService, FormDataSerializerService, ToastService) ->
    'ngInject'
    @$state = $state
    @RESTService = RESTService
    @FormDataSerializerService = FormDataSerializerService
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @$$fetchProduct().then => @loaded = true

  updateProduct: (product) ->
    delete product.image unless product.image instanceof File
    product
      .withHttpConfig(transformRequest: angular.identity)
      .customPUT(@FormDataSerializerService(product), '', undefined, {'Content-Type': undefined})
      .then =>
        @ToastService.success('Product was successfully updated!')
        @$state.go('^.list')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  $$fetchProduct: ->
    @RESTService
      .one('admin/products', @$state.params.productId)
      .get()
      .then (response) =>
        @product = response.data

