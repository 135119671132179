module.exports = class EditReviewCtrl
  constructor: (ToastService) ->
    'ngInject'
    @ToastService = ToastService

  submit: ->
    @review.put()
      .then =>
        @ToastService.success('Review successfully updated!')
      .catch (response) =>
        if response
          @ToastService.error(response.data.error)

