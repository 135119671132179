_ = require('lodash')

module.exports = class CitiesListContainerCtrl
  constructor: (CitiesService, $state, ToastService, PromptService) ->
    'ngInject'
    @CitiesService = CitiesService
    @$state = $state
    @ToastService = ToastService
    @PromptService = PromptService

  $onInit: ->
    @citiesService = @CitiesService.service(@$state.params.countryId)
    @loaded = false
    @query =
      order: @$state.params?.order || "name"

    @fetchCities().then => @loaded = true

  deleteCity: (city) ->
    @PromptService
      .show(title: "Do you realy want to delete city #{city.name.en}?")
      .then =>
        city.remove()
      .then =>
        _.remove(@cities, id: city.id)
        @ToastService.success('City successfully delete!')
      .catch (response) =>
        if response
          @ToastService.error(response.data.error)

  fetchCities: ->
    @$state.go('.', @query)

    @citiesService
      .getList(@query)
      .then (response) =>
        @cities = response.data
        @total = response.headers('x-total')
