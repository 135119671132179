require('./product-brands-form.scss')

module.exports = {
  template: require('./product-brands-form.pug')()
  controller: require('./product-brands-form.ctrl')
  bindings:
    productBrand: '<'
    isNew: '<'
    onSubmit: '&'
}
