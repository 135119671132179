require('./product-categories-form.scss')

module.exports = {
  template: require('./product-categories-form.pug')()
  controller: require('./product-categories-form.ctrl')
  bindings:
    productCategory: '<'
    isNew: '<'
    onSubmit: '&'
}
