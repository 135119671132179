angular = require('angular')

module.exports =
  angular.module('App.Countries', [])
    .factory 'CountriesService', require('./services/countries.service')

    # Container
    .component 'countriesListContainer', require('./containers/countries_list_container/countries-list-container')
    .component 'countriesEditContainer', require('./containers/countries_edit_container/countries-edit-container')
    .component 'countriesNewContainer', require('./containers/countries_new_container/countries-new-container')

    # UI components
    .component 'countriesList', require('./components/countries_list/countries-list')
    .component 'countriesForm', require('./components/countries_form/countries-form')

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'countries',
          abstract: true
          url: '/countries'
          template: '<ui-view/>'
          data:
            loginRequired: true
        .state 'countries.list',
          url: '?{q}{order}'
          component: 'countriesListContainer'
          ncyBreadcrumb:
            label: 'Countries'
          params:
            q: { dynamic: true, squash: true, value: null, type: 'string' }
            order: { dynamic: true, squash: true, value: null, type: 'string' }
        .state 'countries.new',
          url: '/new'
          component: 'countriesNewContainer'
          ncyBreadcrumb:
            label: 'New Country'
            parent: 'countries.list'
        .state 'countries.edit',
          url: '/:countryId'
          component: 'countriesEditContainer'
          resolve:
            country: (CountriesService, $stateParams) ->
              'ngInject'
              CountriesService
                .service()
                .one($stateParams.countryId)
                .get()
                .then (response) ->
                  response.data
          ncyBreadcrumb:
            label: '{{$$childHead.$ctrl.country.name}}'
            parent: 'countries.list'
