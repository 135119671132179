module.exports = class ListCtrl
  constructor: ($state, FeedbackService) ->
    'ngInject'
    @$state = $state
    @FeedbackService = FeedbackService

  $onInit: ->
    @query =
      order: @$state.params?.order || '-created_at'
      per_page: @$state.params?.per_page || 25
      page: @$state.params?.page || 1
      rows_array: [15, 25, 50]
    @fetchFeedbacks = @fetchFeedbacks.bind(@)
    @fetchFeedbacks()

  fetchFeedbacks: ->
    @$state.go('.', @query)

    @FeedbackService.getList(@query)
      .then (response) =>
        @feedbacks = response.data
        @total = response.headers('x-total')
