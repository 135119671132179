angular = require('angular')
_ = require('lodash')

module.exports =
  angular
  .module('App.Components.ServiceAddons', [])

  .component('serviceAddons', require('./service_addons'))
  .component('serviceAddonsList', require('./list'))
  .component('serviceAddonForm', require('./form'))

  .config(($stateProvider) ->
    'ngInject'
    $stateProvider
      .state 'services_addons',
        url: '/services_addons'
        abstract: true
        params:
          service: {}
        template: '<ui-view></ui-view>'
      .state 'services_addons.list',
        url: '/'
        params:
          service: {}
        views:
          '^.$default': 'serviceAddonsList'
      .state 'services_addons.new',
        url: '/new'
        params:
          serviceAddon: {}
        views:
          '^.$default': 'serviceAddonsForm'
      .state 'services_addons.edit',
        url: '/:serviceAddonId/edit'
        params:
          serviceAddon: {}
        views:
          '^.$default': 'serviceAddonsForm'
  )
