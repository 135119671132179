angular = require('angular')

module.exports =
  angular.module('App.Components.SmsCampaigns', [])
    .component('smsCampaigns', require('./containers/sms_campaigns/sms-campaigns'))
    .component('smsCampaign', require('./containers/sms_campaign/sms-campaign'))

    .component('smsCampaignsList', require('./components/sms_campaigns_list/sms-campaigns-list'))
    .component('smsCampaignShow', require('./components/sms_campaign_show/sms-campaign-show'))

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'sms_campaigns',
          abstract: true
          url: '/sms_campaigns/'
          template: '<ui-view/>'
          ncyBreadcrumb:
            label: 'SMS Campaigns'
          data:
            loginRequired: true
        .state 'sms_campaigns.list',
          url: '?{order}{per_page}{page}{q}'
          component: 'smsCampaigns'
          ncyBreadcrumb:
            label: 'SMS Campaigns'
          params:
            order: { dynamic: true, squash: true, value: null, type: 'string' }
            per_page: { dynamic: true, squash: true, value: null, type: 'int' }
            page: { dynamic: true, squash: true, value: null, type: 'int' }
            q: { dynamic: true, squash: true, value: null, type: 'string' }
        .state 'sms_campaigns.show',
          url: ':smsCampaignId'
          component: 'smsCampaign'
          ncyBreadcrumb:
            label: '{{$$childHead.$ctrl.smsCampaign.name}}'
            parent: 'sms_campaigns.list'
