_ = require('lodash')

module.exports = ->
  (data) ->
    res = _.pickBy({
      name: data.name
      description: data.description
      ar_name: data.ar_name
      image: if data.image instanceof File then data.image else null
      service_ids: data.service_ids
      sort_rank: data.sort_rank
      starts_at: data.starts_at
      expires_at: data.expires_at
      tags: data.tags
      price: data.price
    }, (v) -> !!v)
    res.is_home = data.is_home
    res
