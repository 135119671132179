require('./cities-list.scss')

module.exports = {
  template: require('./cities-list.pug')()
  controller: require('./cities-list.ctrl')
  bindings:
    country: '<'
    query: '<'
    cities: '<'
    total: '<'
    onRefresh: '&'
    onCityDelete: '&'
}
