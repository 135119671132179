_ = require('lodash')
config = require('config')

module.exports = (Restangular, $cookies, $state, $timeout) ->
  'ngInject'
  $$token = -> $cookies.get("token")
  $$baseUrl = config.baseApiUrl

  RESTService = {
    token: ->
      return {} if !@isAuthorized()
      JSON.parse($$token())

    isAuthorized: ->
      $$token()?.length > 0

    updateRestangularSettings: ->
      $$buildAndMergeRestangular()

    logout: ->
      $cookies.remove('token')
      $state.go('login')

    setToken: (token) ->
      $cookies.put("token", JSON.stringify(_.pick(token, ['role', 'user_auth_token'])))
      @updateRestangularSettings()

    handleAccess: (event, toState, toParams, fromState, fromParams) ->
      if toState.data?.loginRequired && !@isAuthorized()
        $state.go('login')

    setMainRequestUrl: (baseUrl) ->
      $cookies.put("baseUrl", $$baseUrl)
      $$buildAndMergeRestangular()

    getBaseUrl: ->
      $$baseUrl

    isAdminRole: ->
      @token().role == 'admin'

    isCallCenterOperatorRole: ->
      @token().role == 'call_center_operator'
  }

  $$buildAndMergeRestangular = ->
    if _.isEmpty($cookies.get('token'))
      $state.go('login')
    rest = Restangular.setBaseUrl($$baseUrl).setFullResponse(true)
    rest.setErrorInterceptor((response, deferred, responseHandler) ->
      if response.status == 401
        RESTService.logout()
        $state.go('login')
        return false
      true)
    rest.setDefaultHeaders("X-User-Token": RESTService.token().user_auth_token)
    _.merge(RESTService, rest)

  $$buildAndMergeRestangular()
  RESTService
