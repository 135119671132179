angular = require('angular')

module.exports = angular.module('App.client_versions', [])

.controller('client_versions.ListCtrl', require('./controllers/list.ctrl'))
.controller('client_versions.EditCtrl', require('./controllers/edit.ctrl'))

.factory('ClientVersionService', require('./services/client_version.service'))

.config(($stateProvider) ->
  'ngInject'
  $stateProvider
    .state 'client_versions',
      url: '/client_versions'
      abstract: true
      template: '<ui-view/>'
      data:
        loginRequired: true

    .state 'client_versions.list',
      controller: 'client_versions.ListCtrl'
      controllerAs: 'list_ctrl'
      url: '/'
      template: require('./templates/list.pug')()
      ncyBreadcrumb:
        label: 'Client Versions'

    .state 'client_versions.edit',
      controller: 'client_versions.EditCtrl'
      controllerAs: 'edit_ctrl'
      url: '/:id'
      template: require('./templates/edit.pug')()
      ncyBreadcrumb:
        label: 'Client Version Info'
        parent: 'client_versions.list'

)
