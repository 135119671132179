module.exports = class EditCtrl
  constructor: (SettingsService, ToastService) ->
    'ngInject'
    @SettingsService = SettingsService
    @ToastService = ToastService
    @form = @SettingsService.one().get().$object

  submit: ->
    @SettingsService.one().customPUT(@form)
      .then (response) =>
        @ToastService.success('Settings was successfully updated!')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)
