require('./countries-list.scss')

module.exports = {
  template: require('./countries-list.pug')()
  controller: require('./countries-list.ctrl')
  bindings:
    countries: '<'
    total: '<'
    query: '<'
    onRefresh: '&'
    onCountryDelete: '&'
}
