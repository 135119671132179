require('./owner-registration-requests-list.scss')

module.exports = {
  template: require('./owner-registration-requests-list.pug')()
  controller: require('./owner-registration-requests-list.ctrl')
  bindings:
    query: '<'
    registrationRequests: '<'
    total: '<'
    onRegistrationRequestMarked: '&'
    onRefresh: '&'
}
