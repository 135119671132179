angular = require('angular')

module.exports =
  angular
  .module('App.Users', [])
  .factory 'UsersService', require('./services/users.service')

  # Container
  .component 'usersListContainer', require('./containers/users_list_container/users-list-container')
  .component 'usersEditContainer', require('./containers/users_edit_container/users-edit-container')
  .component 'usersCreateContainer', require('./containers/users_create_container/users-create-container')

  # UI components
  .component 'usersList', require('./components/users_list/users-list')
  .component 'usersForm', require('./components/users_form/users-form')
  .component 'usersCredits', require('./components/users_credits/users-credits')

  .config ($stateProvider) ->
    'ngInject'
    $stateProvider
      .state 'users',
        abstract: true
        url: '/users'
        template: '<ui-view/>'
        data:
          loginRequired: true
      .state 'users.list',
        url: '/?{order}{per_page}{page}{q}'
        component: 'usersListContainer'
        ncyBreadcrumb:
          label: 'Users'
        params:
          order: { dynamic: true, squash: true, value: null, type: 'string' }
          per_page: { dynamic: true, squash: true, value: null, type: 'int' }
          page: { dynamic: true, squash: true, value: null, type: 'int' }
          q: { dynamic: true, squash: true, value: null, type: 'string' }
      .state 'users.edit',
        url: '/:userId/edit'
        component: 'usersEditContainer'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.form.first_name}}'
          parent: 'users.list'

      .state 'users.new',
        url: '/new'
        component: 'usersCreateContainer'
        ncyBreadcrumb:
          label: 'New User'
          parent: 'users.list'
