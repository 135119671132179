_ = require('lodash')
angular = require('angular')

module.exports = class NewCtrl
  ANDROID    = 'android'
  IOS        = 'ios'
  REGISTERED = 'registered'
  ALL        = 'all'
  SALON      = 'salon'
  COUNTRY    = 'country'
  CITY       = 'city'
  TYPES      = [ALL, REGISTERED, ANDROID, IOS, SALON, COUNTRY, CITY]

  constructor: (UsersService, RESTService, SalonService, $mdDialog, $state, GetMatchesService) ->
    'ngInject'
    @UsersService      = UsersService
    @RESTService       = RESTService
    @SalonService      = SalonService
    @$mdDialog         = $mdDialog
    @$state            = $state
    @getMatchesService = GetMatchesService

    @form =
      message: {}
      data: {}
      campaign_type: ALL
      fallback: null
      sound: false
      country_id: null
      city_id: null

  $onInit: ->
    @query =
      q: @$state.params?.q || ''
    @RESTService.one('admin/supported_countries').get()
      .then (response) =>
        @countries = response.data.countries
        @country   = _.find @countries, id: parseInt(@query.country_id)

  setCountryId: ->
    @query.country_id = @country?.id
    @form.country_id  = @country?.id
    @cities    = @country?.cities

  setCityId: ->
    @query.city_id = @city?.id
    @form.city_id  = @city?.id

  campaignTypes: -> TYPES

  confirm: ->
    @$mdDialog.show(
      resolve:
        campaign: => @form
      bindToController: true
      controller: 'notifications_campaigns.ModalCtrl'
      controllerAs: 'ctrl'
      template: require('../templates/modal.pug')()
      parent: angular.element(document.body)
      clickOutsideToClose: true)
