PAYMENT_DEFAULT_FILTER_STATES = require('app-constants').PAYMENT_DEFAULT_FILTER_STATES

module.exports = class PaymentsCtrl
  constructor: ($q, $scope, $state, PaymentService) ->
    'ngInject'
    @$q = $q
    @$state = $state
    @PaymentService = PaymentService

  $onInit: ->
    @loaded = false
    @updateTable = @updateTable.bind(@)
    @query =
      order: @$state.params?.order || '-created_at'
      per_page: @$state.params?.per_page || 15
      states: @statesFromParams()
      rows_array: [15, 25, 50]
      timeout: false
      page: @$state.params?.page || 1
      q: @$state.params?.q || ''
    @$q.all([
      @updateTable(),
      @fetchStates()
    ]).then => @loaded = true

  statesFromParams: ->
    if !@$state.params?.states
      PAYMENT_DEFAULT_FILTER_STATES
    else if @$state.params.states instanceof Array
      @$state.params.states
    else
      new Array(@$state.params.states)

  failedStatesChecked: ->
    !!_.intersection(@query.states, ['failed', 'failed_without_refund']).length

  fetchStates: ->
    @PaymentService.one('existent_states')
      .get()
      .then (response) =>
        @states = response.data.states

  updateTable: ->
    @$state.go('.', _.merge(@queryUrlParams(), states: @query.states))

    @PaymentService
      .getList(_.merge(@queryUrlParams(), 'states[]': @query.states))
      .then (response) =>
        @payments = response.data
        @total = response.headers('x-total')

  queryUrlParams: ->
    order: @query.order,
    per_page: @query.per_page,
    page: @query.page,
    q: @query.q
    timeout: @query.timeout
