SALON_TYPES = require('app-constants').SALON_TYPES
_ = require('lodash')
moment = require('moment')

module.exports = class FormCtrl
  NEVER = 'never'
  REPEATS = ['never', 'daily', 'weekly', 'monthly', 'yearly']

  $onInit: ->
    @showTypeFlags = @salon.type == SALON_TYPES['Default'] if @showTypeFlags
    @repeats = REPEATS
    @scheduleBreak.repeat ||= NEVER
    @scheduleBreak.is_home = true if _.isUndefined(@scheduleBreak.is_home)
    @scheduleBreak.is_in_salon = true if _.isUndefined(@scheduleBreak.is_in_salon)

  resetRepeatEndsAt: ->
    @scheduleBreak.repeat_ends_at = null if @scheduleBreak.repeat == NEVER

  submit: ->
    @onSubmit(scheduleBreak: @scheduleBreak)

  isEndableRepeat: ->
    @scheduleBreak.repeat != NEVER
