module.exports = class AreasEditContainerCtrl
  constructor: (AreasService, $state, ToastService) ->
    'ngInject'
    @AreasService = AreasService
    @$state = $state
    @ToastService = ToastService

  $onInit: ->
    @areasService = @AreasService.service(@$state.params.countryId, @$state.params.cityId)
    @loaded = false
    @$$fetchArea().then => @loaded = true

  updateArea: (area) ->
    area
      .put()
      .then =>
        @ToastService.success('Area successfully updated!')
        @$state.go('^.list')
      .catch (response) =>
        @ToastService.error(response.data.error)

  $$fetchArea: ->
    @areasService.one(@$state.params.areaId)
      .get()
      .then (response) =>
        @area = response.data
