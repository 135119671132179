require('./currencies-list.scss')

module.exports = {
  template: require('./currencies-list.pug')()
  controller: require('./currencies-list.ctrl')
  bindings:
    currencies: '<'
    query: '<'
    onRefresh: '&'
}
