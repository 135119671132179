angular = require('angular')
_ = require('lodash')

HomeImage = require('images/salon-services-home.png')
InSalonImage = require('images/salon-services-salon.png')

NoCategory = {id: undefined, name: { en: 'No Category' }, noDrag: true }

module.exports = class CategoriesCtrl
  constructor: ($q, $state, ToastService, ServiceService, CategoriesService) ->
    'ngInject'
    @ToastService = ToastService
    @$state = $state
    @$q = $q
    @CategoriesService = CategoriesService
    @serviceService = ServiceService.service(@$state.params.salonId)

  $onInit: ->
    @loaded = false
    @hidden = {}
    @CategoriesService.getList()
      .then (response) =>
        @categories = response.data
        @categories.push(NoCategory)

        @serviceService.getList()
          .then (response) =>
            usedCategoriesIds = _.uniq(_.map(response.data, 'category_id'))
            if usedCategoriesIds.includes(undefined)
              usedCategoriesIds = _.remove(usedCategoriesIds, undefined)
              usedCategoriesIds.push(undefined)

            @servicesHash = _.groupBy(response.data, (service) -> service.category_id)
            @orderedCategories = _.map(usedCategoriesIds, (categoryId) => _.find(@categories, id: categoryId))
            @warningMessagesHash = _.reduce(response.data, (acc, service) =>
              acc[service.id] = @serviceWarningMessages(service)
              acc
            , {})
            @loaded = true

  serviceWarningMessages: (service) ->
    _.compact([
      !service.stylist_ids.length && 'No stylists assigned',
      !(service.name && service.ar_name) && 'Missing transalation'
    ]).join('. ')

  toggle: (category) ->
    @hidden[category.id] = !@hidden[category.id]

  onDndMove: ($index) ->
    @orderedCategories.splice($index, 1)
    @submitServicesSort()

  submitServicesSort: ->
    services = _.map(@orderedCategories, (category) => @servicesHash[category.id])
      .reduce (a1, a2) ->
        a1.concat(a2)

    @serviceService.one('sort').customPOST(ids: _.map(services, (s) -> s.id))
      .then =>
        @ToastService.success('Services sorted successfully!')
