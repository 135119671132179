ace = require('brace')
require('brace/mode/json')
_ = require('lodash')
nanoid = require('nanoid')

module.exports = class JsonEditorCtrl
  constructor: ($timeout) ->
    'ngInject'
    @$timeout = $timeout

  $onInit: ->
    @editorId = "json-editor-#{nanoid()}"
    @jsonValid = true

    @$timeout =>
      @editor = ace.edit(@editorId)
      @editor.getSession().setMode('ace/mode/json')
      @editor.setOptions(maxLines: Infinity)
      @editor.setReadOnly(@readOnly)
      @editor.setValue(JSON.stringify(@ngModel.$viewValue, null, 2), -1)
      @editor.clearSelection()

      @editor.on 'change', (e) =>
        try
          @jsonValid = true
          @ngModel.$setViewValue(JSON.parse(@editor.getValue()))
        catch
          @jsonValid = false

      @editor.on 'blur', () =>
        @editor.clearSelection()

    @ngModel.$validators.isJson = => @jsonValid

    @ngModel.$render = =>
      return unless @editor
      if !!@ngModel.$viewValue
        @editor.setValue(JSON.stringify(@ngModel.$viewValue, null, 2), -1)
        @editor.clearSelection()
