if process.env.SERVER_ENV != 'development'
  require('rollbar-browser').init
    accessToken: 'b1b03226b58e43f3864a07573892edc9'
    captureUncaught: true
    payload:
      environment: process.env.SERVER_ENV

require('./index.scss')

config = require('config')
angular = require('angular')

angular.module('App', [
  require('./core').name

  require('./modules/modules.module').name

  # TODO: refactor to components only mode
  require('./payments').name
  require('./notifications').name
  require('./notifications_campaigns').name
  require('./client_versions').name
  require('./settings').name
  require('./feedbacks').name
])
# NOTE: overriding angularjs exception handler to handle rollbar errors
.decorator('$exceptionHandler', ($delegate, $window) ->
  'ngInject'
  (exception, cause) ->
    if $window.Rollbar
      Rollbar.error(exception, cause: cause)
    $delegate(exception, cause)
)
.config(($mdThemingProvider) ->
  'ngInject'
  $mdThemingProvider.theme("error-toast")
  $mdThemingProvider.theme("success-toast")
  $mdThemingProvider.theme('default')
    .primaryPalette('purple')
    .accentPalette('cyan')
    .warnPalette('red')
    .backgroundPalette('grey'))
.config(($compileProvider) ->
  'ngInject'
  $compileProvider.debugInfoEnabled(config.ENV == 'development' || config.ENV == 'test'))
.config(($mdIconProvider) ->
  'ngInject'
  $mdIconProvider
    .iconSet('social', 'img/icons/sets/social-icons.svg', 24)
    .defaultIconSet('img/icons/sets/core-icons.svg', 24))
.config(($httpProvider) ->
  'ngInject'
  $httpProvider.useApplyAsync(true))
.config((uiGmapGoogleMapApiProvider) ->
  'ngInject'
  uiGmapGoogleMapApiProvider.configure key: 'AIzaSyDmSCAQ2QO4rHdpxfInpkzoy8J3sTRubsM')
# .config(($locationProvider) ->
#   'ngInject'
#   $locationProvider.html5Mode(true))

.directive 'goBack', ($window) ->
  'ngInject'
  ($scope, $element) ->
    $element.on 'click', () ->
      $window.history.back()

.run(($rootScope, ToastService, $state) ->
  'ngInject'
  $rootScope.$on '$stateChangeError', (event, toState, toParams, fromState, fromParams, error) ->
    ToastService.error(error.data.error)
    if fromState.name.length
      $state.go fromState.name
    else
      $state.go 'bad_request')
