require('./services-duplicate.scss')
_ = require('lodash')

module.exports = class ServicesDuplicateCtrl
  constructor: ($state, RESTService, ToastService, $mdDialog, ServiceService) ->
    'ngInject'
    @loaded = false
    @$mdDialog = $mdDialog
    @$state = $state
    @ToastService = ToastService
    @ServiceService = ServiceService.service(@salonId).one(@service.id.toString()).one('duplicate')
    @form = { includes_addons: true }
    @salonId = parseInt(@salonId)
    RESTService.setFullResponse(true).one('admin/salons/active').get({ user_id: @userId })
      .then (response) =>
        @salons = _.map response.data, (salon) =>
          salon.searchText = (salon.name || salon.ar_name).toLowerCase()
          if salon.id == @salonId
            @form.targetSalon = salon

          salon

        @loaded = true


  matchedSalons: (q) ->
    if q
      @salons.filter( @createFilterFor(q) )
    else
      @salons

  createFilterFor: (q) ->
    regexp = new RegExp(q, 'gi')
    (salon) -> regexp.test(salon.searchText)

  submit: ->
    @$mdDialog.hide()
    @ServiceService.post(null, to_salon_id: @form.targetSalon.id, includes_addons: @form.includes_addons)
      .then (response) =>
        @$state.go('salons.edit.services.edit', salonId: @form.targetSalon.id, serviceId: response.data.id)
      .catch (response) =>
        ToastService.error(response.data.error)
