_ = require('lodash')

module.exports = class NotificationsCtrl
  constructor: ($scope, NotificationService, ToastService, $state, PromptService) ->
    'ngInject'

    @PromptService = PromptService
    @NotificationService = NotificationService
    @ToastService = ToastService
    @$state = $state
    @query =
      order: ""
      per_page: 15
      page: 1
      rows_array: [15, 25, 50]

    $scope.updateTable = =>
      @$state.go('.', per_page: @query.per_page, page: @query.page)
      NotificationService.getList(@query).then (response) =>
        @notifications = _.sortBy(response.data, (n) -> -parseInt(n.status.update_time, 10))
        @total = response.headers('x-total')

    $scope.updateTable()

  cancel: (notification) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete notification '#{notification.jid}'?"
    ).then =>
      @NotificationService
        .one(notification.jid)
        .remove()
        .then (response) =>
          _.remove(@notifications, jid: response.data.jid)
          @ToastService.success('Notification canceled successfully')
        .catch (response) =>
          @ToastService.error(response.data.error)

  canCancel: (notification) ->
    notification.status.status != 'complete'

  updateTimeFor: (notification) ->
    new Date(parseInt(notification.status.update_time, 10) * 1000).toString()
