angular = require('angular')

module.exports =
  angular
  .module('App.ProductCategories', [])

  .component('productCategoriesContainer', require('./containers/product_categories_container/product-categories-container'))
  .component('productCategoriesEditContainer', require('./containers/product_categories_edit_container/product-categories-edit-container'))
  .component('productCategoriesNewContainer', require('./containers/product_categories_new_container/product-categories-new-container'))

  .component('productCategoriesList', require('./components/product_categories_list/product-categories-list'))
  .component('productCategoriesForm', require('./components/product_categories_form/product-categories-form'))

  .config ($stateProvider) ->
    'ngInject'

    $stateProvider
      .state 'product_categories',
        url: '/product_categories'
        abstract: true
        data:
          loginRequired: true
      .state 'product_categories.list',
        url: '/?{order}{per_page}{page}{q}'
        component: 'productCategoriesContainer'
        ncyBreadcrumb:
          label: 'Product Categories'
        params:
          q: { dynamic: true, squash: true, value: null, type: 'string' }
          order: { dynamic: true, squash: true, value: null, type: 'string' }
          per_page: { dynamic: true, squash: true, value: null, type: 'int' }
          page: { dynamic: true, squash: true, value: null, type: 'int' }

      .state 'product_categories.new',
        url: '/new'
        component: 'productCategoriesNewContainer'
        ncyBreadcrumb:
          label: 'New Product Category'
          parent: 'product_categories.list'
      .state 'product_categories.edit',
        url: '/:productCategoryId'
        component: 'productCategoriesEditContainer'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.productCategory.name.en}}'
          parent: 'product_categories.list'
