module.exports = class AdjustmentFormCtrl
  constructor: ->
    'ngInject'

  $onInit: ->
    @record = {}
    @record.salon_id = @salonId if @salonId

  submit: ->
    @onSubmit(record: @record)
