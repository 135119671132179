require('./availability-times.scss')

module.exports = {
  template: require('./availability-times.pug')()
  controller: require('./availability-times.ctrl')
  bindings:
    selection: '<'
    times: '<'
    onBack: '&'
}
