TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

_ = require('lodash')

module.exports = class EditSalonCtrl
  constructor: (ToastService, PromptService, RESTService, SalonTypesService) ->
    'ngInject'
    @ToastService = ToastService
    @PromptService = PromptService
    @RESTService = RESTService
    @SalonTypesService = SalonTypesService

  $onInit: ->
    @$$refreshInitialType()
    @salonTypesDefinition = @SalonTypesService(@salon)

  $$refreshInitialType: ->
    @initialType = @salon.type

  transferInformationWarning: ->
    result = ""
    transfer_information = @salon.transfer_information
    result += "Please enter transfer information data to automate bank payments." if !transfer_information?.next_payout_at
    result
  inSalon: ->
    @salonTypesDefinition.isDefault() || @salonTypesDefinition.inSalonOnly()

  inHome: ->
    @salonTypesDefinition.isDefault() || @salonTypesDefinition.inHomeOnly()

  updateBranchioLink: ->
    @submit(@salon).then =>
      @RESTService.one('admin/salons', @salon.id).post('create_branchio_link')
        .then (response) =>
          @ToastService.success(TOAST_NOTIFICATION.updated_successfully)
          @salon.branchio_link = response.data.branchio_link
        .catch (response) =>
          @ToastService.error(response.data.error)

  submit: (salon)->
    if salon.type != @initialType
      @PromptService
        .show(title: 'Changing business type of any existing salon will turn all services to match salon type. If it is ok for you click "OK" otherwise clean up services or turn to proper type manualy.')
        .then => @$$update(salon)
    else
      @$$update(salon)

  $$update: (salon) ->
    salon
      .put()
      .then (response) =>
        @ToastService.success(TOAST_NOTIFICATION.updated_successfully)
        @$$refreshInitialType()
        @salon = response.data
      .catch (response) =>
        @ToastService.error(response.data.error)
