_ = require('lodash')

module.exports = class LoginCtrl
  constructor: (RESTService, ToastService, $scope, $state) ->
    'ngInject'

    @RESTService = RESTService
    @ToastService = ToastService
    @$scope = $scope
    @$state = $state

  $onInit: ->
    @$state.go('salons.list') if @RESTService.isAuthorized()

  login: ->
    @$scope.loginForm.$error = {}
    @RESTService.oneUrl('admin').post('session', @form)
      .then (response) =>
        @RESTService.setToken(response.data)
        @ToastService.success('Logined successfully!')
        if @RESTService.isAdminRole()
          @$state.go('salons.list')
        else
          @RESTService.logout()
          $state.go('login')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)
