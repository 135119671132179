angular = require('angular')
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class ModalCtrl
  constructor: (campaign, NotificationsCampaignService, ToastService, $state, $mdDialog, FormDataSerializerService) ->
    'ngInject'
    @campaign = campaign
    @NotificationsCampaignService = NotificationsCampaignService
    @ToastService = ToastService
    @$state = $state
    @$mdDialog = $mdDialog
    @FormDataSerializerService = FormDataSerializerService

  dataJson: ->
    angular.toJson(@campaign.data, true)

  submit: ->
    @NotificationsCampaignService
      .withHttpConfig(transformRequest: angular.identity)
      .customPOST(@FormDataSerializerService(@campaign), '', undefined, {'Content-Type': undefined})
      .then (response) =>
        @ToastService.success(TOAST_NOTIFICATION.created_successfully)
        @$state.go('notifications_campaigns.list')
        @$mdDialog.hide()
      .catch (response) =>
        @ToastService.error(response.data.error)
