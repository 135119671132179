module.exports = class UsersListContainerCtrl
  constructor: ($state, RESTService, PromptService, ToastService, UsersService) ->
    'ngInject'
    @$state = $state
    @RESTService = RESTService
    @PromptService = PromptService
    @ToastService = ToastService
    @UsersService = UsersService

  $onInit: ->
    @loaded = false
    @query =
      order: @$state.params?.order || '-created_at'
      per_page: @$state.params?.per_page || 15
      rows_array: [15, 25, 50]
      page: @$state.params?.page || 1
      q: @$state.params?.q || ''
    @fetchUsers(@query)

  fetchUsers: ->
    @$state.go('.', order: @query.order, per_page: @query.per_page, page: @query.page, q: @query.q)
    @UsersService
      .getList(@query)
      .then (response) =>
        @users = response.data
        @total = response.headers('x-total')
        @loaded = true

  deleteUser: (user) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete user '#{user.first_name} #{user.last_name}'?"
    ).then =>
      @UsersService.one(user.id.toString()).remove()
        .then =>
          @fetchUsers(@query)
          @ToastService.success("User successfully deleted!")
