angular = require('angular')

module.exports =
  angular
  .module('App.Components.ActivityLogs', [])
  .component('activityLog', require('./containers/activity_log/activity-log'))
  .component('changeValues', require('./components/change_values/change-values'))
  .config ($stateProvider) ->
    'ngInject'
    activityLogResourceStatesResolvers = [
      {
        name: 'salons.edit',
        trackedObject: (salon) ->
          'ngInject'
          salon
        render_property: {
          name: 'template',
          value: '<ui-view></ui-view>'
        }
      },
      {
        name: 'salons.edit.stylists.edit',
        trackedObject: (StylistService, $stateParams) ->
          'ngInject'
          StylistService($stateParams.salonId).one($stateParams.stylistId).get().then (response) -> response.data,
        render_property: {
          name: 'views',
          value:
            editStylistContainer:
              template: '<ui-view/>'
        }
      },
      {
        name: 'salons.edit.services.edit',
        trackedObject: (service) ->
          'ngInject'
          service
        render_property: {
          name: 'views',
          value:
            ActivityLogContainer:
              template: '<ui-view/>'
        }
      },
      {
        name: 'salons.edit.breaks.edit',
        trackedObject: (scheduleBreak) ->
          'ngInject'
          scheduleBreak
        render_property: {
          name: 'views',
          value:
            ActivityLogContainer:
              template: '<ui-view/>'
        }
      },
      {
        name: 'salons.edit.stylists.edit.schedule_breaks.edit',
        trackedObject: (scheduleBreak) ->
          'ngInject'
          scheduleBreak
        render_property: {
          name: 'views',
          value:
            ActivityLogContainer:
              template: '<ui-view/>'
        }
      }]
    _.forEach activityLogResourceStatesResolvers, (new_state) ->
      $stateProvider
        .state "#{new_state.name}.activity_log",
          url: '/activity_log'
          abstract: true
          "#{new_state.render_property.name}": new_state.render_property.value
          resolve:
            trackedObject: new_state.trackedObject
        .state "#{new_state.name}.activity_log.list",
          url: '/'
          component: 'activityLog'
          ncyBreadcrumb:
            label: 'Activity Log'
