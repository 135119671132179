require('./styles/index.scss')
require('./styles/image_gallery.scss')
angular = require('angular')

module.exports = angular.module('App.salons', [
  require('./modules/stylists').name
  require('./modules/special_offers')
])

.controller('SalonOwnerTabCtrl', require('./controllers/salon_owner_tab.ctrl'))
.controller('UploadImageTabCtrl', require('./controllers/upload_image_tab.ctrl'))

.component('editSalon', require('./containers/edit_salon/edit-salon'))
.component('newSalon', require('./containers/new_salon/new-salon'))
.component('salons', require('./containers/salons/salons'))
.component('reviews', require('./containers/reviews/reviews'))
.component('editReview', require('./containers/edit_review/edit-review'))

.component('paymentSettings', require('./components/payment_settings/payment-settings'))
.component('salonForm', require('./components/salon_form/salon-form'))
.component('locationForm', require('./components/location_form/location-form'))
.component('salonTypeSelector', require('./components/salon_type_selector/salon-type-selector'))
.component('workingHours', require('./components/working_hours/working-hours'))
.component('homeWorkingHours', require('./components/home_working_hours/home-working-hours'))
.component('reviewForm', require('./components/review_form/review-form'))
.component('salonSearchRanks', require('./components/salon_search_ranks/salon-search-ranks'))
.component('salonMainScreenDetails', require('./components/salon_main_screen_details/salon-main-screen-details'))
.component('salonRefundPolicies', require('./components/salon_search_ranks/salon-search-ranks'))
.component('editTransferInformation', require('./containers/editTransferInformation/editTransferInformation'))

.factory('ClientsService', require('./services/clients.service'))
.factory('SalonService', require('./services/salon.service'))
.factory('ReviewService', require('./services/review.service'))

.config(
  ($stateProvider) ->
    'ngInject'
    $stateProvider
      .state 'salons',
        url: '/'
        abstract: true
        data:
          loginRequired: true
        ncyBreadcrumb:
          label: 'Salons'
        template: '<ui-view/>'
      .state 'salons.list',
        url: '?{order}{per_page}{page}{q}{country_id}'
        component: 'salons'
        params:
          order: { dynamic: true, squash: true, value: null, type: 'string' }
          per_page: { dynamic: true, squash: true, value: null, type: 'int' }
          page: { dynamic: true, squash: true, value: null, type: 'int' }
          q: { dynamic: true, squash: true, value: null, type: 'string' }
          country_id: { dynamic: true, squash: true, value: null, type: 'string' }
        ncyBreadcrumb:
          label: 'Salons'
      .state 'salons.new',
        url: "salons/new"
        component: 'newSalon'
        data:
          loginRequired: true
        ncyBreadcrumb:
          label: 'New Salon'
          parent: 'salons.list'
      .state 'salons.edit',
        url: 'salons/:salonId'
        component: 'editSalon'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.salon.name||edit_ctrl.salon.name}}'
          parent: 'salons.list'
        resolve:
          salon: (SalonService, $stateParams) ->
            'ngInject'

            SalonService
              .one($stateParams.salonId)
              .get()
              .then (response) -> response.data
      .state 'salons.edit.transfer_information',
        url: "/transfer_information"
        component: 'editTransferInformation'
        ncyBreadcrumb:
          label: 'Transfer Information'
      .state 'salons.edit.deposit_policy',
        url: "/deposit_policy"
        template: require('./templates/deposit_policy.pug')()
        ncyBreadcrumb:
          label: 'Deposit policy'
      .state 'salons.edit.location',
        url: "/location"
        component: 'locationForm'
        ncyBreadcrumb:
          label: 'Location'
      .state 'salons.edit.search_ranks',
        url: "/search_ranks"
        component: 'salonSearchRanks'
        ncyBreadcrumb:
          label: 'Search Ranks'
      .state 'salons.edit.payment_settings',
        url: "/payment_settings"
        component: 'paymentSettings'
        ncyBreadcrumb:
          label: 'Payment Settings'
      .state 'salons.edit.contacts',
        url: "/contacts"
        template: require('./templates/contacts.pug')()
        ncyBreadcrumb:
          label: 'Contacts'
      .state 'salons.edit.working_hours',
        url: "/working_hours"
        component: 'workingHours'
        ncyBreadcrumb:
          label: 'Working hours'
      .state 'salons.edit.home_working_hours',
        url: "/home_working_hours"
        component: 'homeWorkingHours'
        ncyBreadcrumb:
          label: 'Home Working hours'
      .state 'salons.edit.owners',
        url: "/owners"
        template: require('./templates/owners.pug')()
        ncyBreadcrumb:
          label: 'Owners'
      .state 'salons.edit.images',
        url: "/images"
        template: require('./templates/images.pug')
        ncyBreadcrumb:
          label: 'Images'
      .state 'salons.edit.breaks',
        url: '/breaks'
        abstract: true
        resolve:
          scheduleBreakService: (SalonScheduleBreakService, $stateParams) ->
            'ngInject'
            SalonScheduleBreakService($stateParams.salonId)
        template: '<ui-view>'
      .state 'salons.edit.breaks.list',
        url: '/'
        component: 'scheduleBreaksList'
        ncyBreadcrumb:
          label: 'Schedule breaks'
      .state 'salons.edit.breaks.new',
        url: '/new'
        component: 'scheduleBreakSave'
        ncyBreadcrumb:
          label: 'New Schedule Break'
          parent: 'salons.edit.breaks.list'
        resolve:
          showTypeFlags: () -> true
          scheduleBreak: (SalonService, RESTService, $stateParams) ->
            'ngInject'
            RESTService.restangularizeElement(
              SalonService.one($stateParams.salonId), {}, 'schedule_breaks')
      .state 'salons.edit.breaks.edit',
        url: '/:scheduleBreakId/edit'
        component: 'scheduleBreakSave'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.scheduleBreak.name}}'
          parent: 'salons.edit.breaks.list'
        resolve:
          showTypeFlags: () -> true
          scheduleBreak: (scheduleBreakService, $stateParams) ->
            'ngInject'
            scheduleBreakService.one($stateParams.scheduleBreakId).get()
            .then (response) -> response.data

      .state 'salons.edit.salon_main_screen_details',
        url: "/salon_main_screen_details"
        component: 'salonMainScreenDetails'
        ncyBreadcrumb:
          label: 'Salon Main Screen Details'
      .state 'salons.edit.services',
        url: '/services'
        abstract: true
        template: '<ui-view></ui-view>'
      .state 'salons.edit.services.categories',
        url: '/'
        component: 'servicesCategories'
        ncyBreadcrumb:
          label: 'Services'

      .state 'salons.edit.services.new',
        url: '/new'
        component: 'serviceSave'
        ncyBreadcrumb:
          label: 'New Service'
          parent: 'salons.edit.services.categories'
        resolve:
          service: (SalonService, RESTService, $stateParams) ->
            'ngInject'
            RESTService.restangularizeElement(
              SalonService.one($stateParams.salonId), {}, 'services')
      .state 'salons.edit.services.edit',
        url: '/:serviceId/edit'
        component: 'serviceSave'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.service.name}}'
          parent: 'salons.edit.services.categories'
        resolve:
          service: (ServiceService, $stateParams) ->
            'ngInject'
            ServiceService.service($stateParams.salonId).one($stateParams.serviceId).get()
              .then (response) -> response.data


      .state 'salons.edit.refund_policies',
        url: '/refund_policies'
        abstract: true
        template: '<ui-view></ui-view>'
      .state 'salons.edit.refund_policies.list',
        url: '/'
        component: 'refundPolicyList'
        ncyBreadcrumb:
          label: 'Refund Policies'
      .state 'salons.edit.refund_policies.new',
        url: '/new'
        component: 'refundPolicySave'
        ncyBreadcrumb:
          label: 'New Refund Policy'
          parent: 'salons.edit.refund_policies.list'
        resolve:
          refundPolicy: (SalonService, RESTService, $stateParams) ->
            'ngInject'
            RESTService.restangularizeElement(
              SalonService.one($stateParams.salonId), {}, 'refund_policies')
      .state 'salons.edit.refund_policies.edit',
        url: '/:refundPolicyId/edit'
        component: 'refundPolicySave'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.service.name}}'
          parent: 'salons.edit.refund_policies.list'
        resolve:
          refundPolicy: (RefundPolicyService, $stateParams) ->
            'ngInject'
            RefundPolicyService.service($stateParams.salonId).one($stateParams.refundPolicyId).get()
              .then (response) -> response.data
      .state 'salons.edit.reviews',
        url: '/reviews'
        abstract: true
        template: '<ui-view></ui-view>'
      .state 'salons.edit.reviews.list',
        url: '/?{per_page}{page}'
        component: 'reviews'
        params:
          per_page: { dynamic: true, squash: true, value: null, type: 'int' }
          page: { dynamic: true, squash: true, value: null, type: 'int' }
        ncyBreadcrumb:
          label: 'Reviews'
      .state 'salons.edit.reviews.edit',
        url: '/:reviewId/edit'
        component: 'editReview'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.review.user.nickname}}'
          parent: 'salons.edit.reviews.list'
        resolve:
          review: (ReviewService, $stateParams) ->
            'ngInject'
            ReviewService($stateParams.salonId).one($stateParams.reviewId).get().then (response) -> response.data
)
