SERVER_DATE_FORMAT = require('app-constants').SERVER_DATE_FORMAT

_ = require('lodash')
moment = require('moment')

module.exports = class TransactionsListContainerCtrl
  constructor: (StatementRecordService, $state, ToastService, revertRecordWithPrompt) ->
    'ngInject'
    @StatementRecordService = StatementRecordService
    @$state = $state
    @revertRecordWithPrompt = revertRecordWithPrompt

  $onInit: ->
    params = @$state.params
    @query = {
      per_page: params?.per_page || 15
      rows_array: [15, 25, 50, { label: 'all', value: => @total }]
      page: params?.page || 1
      salon_id: params?.salon_id
      from: params?.from
      to: params?.to
    }
    @fetchStatements()

  fetchStatements: () ->
    @$state.go('.', @query)
    q = _.clone(@query)
    if q?.per_page > 50
      q.no_pages = true
      @total = q.per_page
    q.from = moment(q.from).format(SERVER_DATE_FORMAT) if q.from
    q.to = moment(q.to).format(SERVER_DATE_FORMAT) if q.to
    @StatementRecordService.one().get(q).then (response) =>
      @statements = response.data
      new_total = response.headers('x-total') || @statements.records.length
      @total = new_total if !@total || new_total > @total

  handleRevert: (record) ->
    @revertRecordWithPrompt(record).then => @fetchStatements()
