module.exports = class StylistFormContainerCtrl
  constructor: ($state, $scope, $timeout) ->
    'ngInject'
    @$state = $state
    @$timeout = $timeout
    @$scope = $scope

  $onInit: ->
    @query =
      q: @$state.params?.q || ''
    @$timeout =>
      @$scope.stylist.user_id = @$scope.stylist.user.id

