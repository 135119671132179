module.exports = class CitiesFormCtrl
  constructor: (TimeZonesService, $scope) ->
    'ngInject'
    @TimeZonesService = TimeZonesService
    @$scope = $scope

  $onInit: ->
    @loaded = false

    @map =
      center:
        latitude: 29.375859
        longitude: 47.9774052
      zoom: 10
      control: {}
    @options =
      scrollwheel: false

    @map.center.latitude = @city.approximate_lat if @city.approximate_lat
    @map.center.longitude = @city.approximate_lng if @city.approximate_lng
    @marker =
      latitude: @city.approximate_lat
      longitude: @city.approximate_lng

    @mapClick = @mapClick.bind(@)
    @timeZonesService = @TimeZonesService.service()
    @$$fetchTimeZones().then => @loaded = true

  $$fetchTimeZones: ->
    @timeZonesService
      .getList()
      .then (response) =>
        @timeZones = response.data

  submit: ->
    @onSubmit(city: @city)

  mapClick: (maps, event, mouseEvent) ->
    @$scope.$applyAsync =>
      coords = mouseEvent[0].latLng
      @marker.latitude = coords.lat()
      @marker.longitude = coords.lng()
      @city.approximate_lat = coords.lat()
      @city.approximate_lng = coords.lng()
