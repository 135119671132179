SALON_TYPES = require('app-constants').SALON_TYPES

_ = require('lodash')

DEFAULT_TYPE = 0

module.exports = class SalonTypeSelectorCtrl
  constructor: ->
    'ngInject'

  $onInit: ->
    @types = SALON_TYPES
    @ngModel.$render = =>
      if @ngModel.$viewValue
        @model = _.clone(@ngModel.$viewValue)
      else
        @model = DEFAULT_TYPE
        @updateModel()

  updateModel: ->
    @ngModel.$setViewValue(_.clone(@model))
