_ = require('lodash')
module.exports = ($mdDialog, $timeout) ->
  'ngInject'
  DEFAULT_PRESET_OPTS =
    title: 'Alert'
    ok: 'Ok'
    cancel: 'Cancel'

  $$preset = $mdDialog.confirm(DEFAULT_PRESET_OPTS)

  $$defaultOrValue = (opts, key) ->
    opts[key] || DEFAULT_PRESET_OPTS[key]

  show: (opts) ->
    $$preset.ok($$defaultOrValue(opts, 'ok'))
    $$preset.cancel($$defaultOrValue(opts, 'cancel'))
    $$preset.title($$defaultOrValue(opts, 'title'))
    $$preset.textContent($$defaultOrValue(opts, 'textContent'))
    $$preset.htmlContent($$defaultOrValue(opts, 'htmlContent'))
    $mdDialog.show($$preset)
      .finally(() => $mdDialog.hide())
