require('./transactionsList.scss')

module.exports = {
  template: require('./transactionsList.pug')()
  controller: require('./transactionsList.ctrl')
  bindings:
    statements: '<'
    query: '<'
    total: '<'
    sum: '<'
    onRefresh: '&'
    onRevert: '&'
}
