angular = require('angular')

module.exports =
  angular.module('App.PromoCodes', [])
    # Container
    .component 'promoCodesListContainer', require('./containers/promo_codes_list_container/promo-codes-list-container')
    .component 'promoCodesEditContainer', require('./containers/promo_codes_edit_container/promo-codes-edit-container')
    .component 'promoCodesNewContainer', require('./containers/promo_codes_new_container/promo-codes-new-container')

    # UI components
    .component 'promoCodesList', require('./components/promo_codes_list/promo-codes-list')
    .component 'promoCodesForm', require('./components/promo_codes_form/promo-codes-form')

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'promo_codes',
          abstract: true
          url: '/promo_codes'
          views : {
            '@': {
              template: '<ui-view/>'
            },
          }
          data:
            loginRequired: true
        .state 'promo_codes.list',
          url: '?{q}{order}'
          component: 'promoCodesListContainer'
          ncyBreadcrumb:
            label: 'Promo Codes'
          params:
            q: { dynamic: true, squash: true, value: null, type: 'string' }
            order: { dynamic: true, squash: true, value: null, type: 'string' }
        .state 'promo_codes.new',
          url: '/new'
          component: 'promoCodesNewContainer'
          ncyBreadcrumb:
            label: 'New Promo Code'
            parent: 'promo_codes.list'
        .state 'promo_codes.edit',
          url: '/:promoCodeId'
          component: 'promoCodesEditContainer'
          resolve:
            promoCode: (RESTService, $stateParams) ->
              'ngInject'
              RESTService.one('admin/promo_codes', $stateParams.promoCodeId).get()
                .then (response) ->
                  response.data
          ncyBreadcrumb:
            label: '{{$$childHead.$ctrl.promoCode.name}}'
            parent: 'promo_codes.list'
