_ = require('lodash')
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class NewCtrl
  constructor: (salon, StylistService, $state, ToastService, SalonTypesService) ->
    'ngInject'
    @$state = $state
    @ToastService = ToastService
    @salon = salon

    @stylist =
      hours: _.clone(salon.hours)
      home_hours: _.clone(salon.home_hours)
      serve_home: true
      serve_salon: true

    @salonTypesDefinition = SalonTypesService(@salon, @stylist)
    @NewStylistService    = StylistService(@$state.params.salonId)

  submit: ->
    @NewStylistService.post(@stylist)
    .then (response) =>
      @$state.go('salons.edit.stylists.list')
      @ToastService.success(TOAST_NOTIFICATION.created_successfully)
    .catch (response) =>
      message = response.data.error
      @ToastService.error(message)
