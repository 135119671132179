PERSISTED_ADDRESS_ATTRIBUTES = require('app-constants').PERSISTED_ADDRESS_ATTRIBUTES
_f = require('lodash/fp')

DEFAULT_LAT = 29.375859
DEFAULT_LNG = 47.9774052

module.exports = class LocationFormCtrl
  constructor: (RESTService, $scope, AreasService, GetMatchesService, SalonService, ToastService) ->
    'ngInject'
    @ToastService = ToastService
    @AreasService = AreasService
    @RESTService = RESTService
    @$scope = $scope
    @getMatchesService = GetMatchesService
    @SalonService = SalonService

  $onInit: ->
    @salonAddressService = @SalonService.one(@salon.id).one('address')
    @mapClick = @mapClick.bind(@)
    if @salon.address
      @salon.address.area_id = @salon.address.area?.id
    @RESTService.one('admin/supported_countries').get()
      .then (response) =>
        @country = _f.find(id: @salon.country_id)(response.data.countries)
        @addressAttributes = _f.difference(Object.keys(@country.address_schema), PERSISTED_ADDRESS_ATTRIBUTES)
        @city = _f.find(id: @salon.city_id)(@country.cities)
        @fetchAreas()
        @initMap()

  fetchAreas: ->
    return unless @city && @country
    @AreasService.service(@country.id, @city.id)
      .getList()
      .then (response) =>
        @areas = response.data

  submit: ->
    @salonAddressService.customPUT(address: @salon.address)
      .then (response) =>
        @ToastService.success('Salon address successfully updated!')
      .catch (response) =>
        @ToastService.error(response.data.error)

  mapClick: (maps, event, mouseEvent) ->
    @$scope.$applyAsync =>
      coords = mouseEvent[0].latLng
      @marker.latitude = coords.lat()
      @marker.longitude = coords.lng()
      @salon.address ||= {}
      @salon.address.lat = coords.lat()
      @salon.address.lng = coords.lng()

  initMap: ->
    @map =
      center:
        latitude: @city.approximate_lat || DEFAULT_LAT
        longitude: @city.approximate_lng || DEFAULT_LNG
      zoom: 10
      control: {}
    @options =
      scrollwheel: false

    @map.center.latitude = @salon.address.lat if @salon.address?.lat
    @map.center.longitude = @salon.address.lng if @salon.address?.lng
    @marker =
      latitude: @map.center.latitude
      longitude: @map.center.longitude
    @mapInitated = true
