require('./gateways-list.scss')

module.exports = {
  template: require('./gateways-list.pug')()
  controller: require('./gateways-list.ctrl')
  bindings:
    gateways: '<'
    query: '<'
    onRefresh: '&'
}
