angular = require('angular')

module.exports =
  angular.module('App.Transactions', [])
    # Container
    .component 'transactionsListContainer', require('./containers/transactionsListContainer/transactionsListContainer')
    .component 'transferShowContainer', require('./containers/transferShowContainer/transferShowContainer')
    .component 'transactionShowContainer', require('./containers/transactionShowContainer/transactionShowContainer')
    .component 'newTransactionContainer', require('./containers/newTransactionContainer/newTransactionContainer')

    # UI components
    .component 'transactionsList', require('./components/transactionsList/transactionsList')
    .component 'adjustmentForm', require('./components/adjustmentForm/adjustmentForm')
    .component 'transactionInfo', require('./components/transactionInfo/transactionInfo')

    .factory 'recordableHref', require('./services/recordableHref')
    .factory 'recordableText', require('./services/recordableText')
    .factory 'revertRecordWithPrompt', require('./services/revertRecordWithPrompt')

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'transactions',
          abstract: true
          url: '/transactions'
          views : {
            '@': {
              template: '<ui-view/>'
            },
          }
          data:
            loginRequired: true
        .state 'transactions.list',
          url: '?{salon_id}{page}{per_page}{from}{to}'
          component: 'transactionsListContainer'
          ncyBreadcrumb:
            label: 'Transactions'
          params:
            salon_id: { dynamic: true, squash: true, value: null, type: 'string' }
            page: { dynamic: true, squash: true, value: null, type: 'string' }
            per_page: { dynamic: true, squash: true, value: null, type: 'string' }
            from: { dynamic: true, squash: true, value: null, type: 'string' }
            to: { dynamic: true, squash: true, value: null, type: 'string' }
        .state 'transactions.new',
          url: '/new?{salon_id}'
          component: 'newTransactionContainer'
          ncyBreadcrumb:
            label: 'Transactions'
          params:
            salon_id: { dynamic: true, squash: true, value: null, type: 'string' }
        .state 'transactions.show',
          url: '/:statementRecordId'
          component: 'transactionShowContainer'
          ncyBreadcrumb:
            label: 'Transactions'
        .state 'transfers',
          abstract: true
          url: '/transfers'
          views : {
            '@': {
              template: '<ui-view/>'
            },
          }
          data:
            loginRequired: true
        .state 'transfers.show',
          url: '/:transfer_id'
          component: 'transferShowContainer'
          ncyBreadcrumb:
            label: 'Transfers'

