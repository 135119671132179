_ = require('lodash')

module.exports = class CategoriesContainerCtrl
  constructor: (RESTService, PromptService, ToastService, CategoriesService) ->
    'ngInject'
    @CategoriesService = CategoriesService
    @PromptService = PromptService
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @$$fetchCategories().then => @loaded = true

  deleteCategory: (category) ->
    @PromptService
      .show(title: "Do you realy want to delete category #{category.name.en}?")
      .then -> category.remove()
      .then =>
        _.remove(@categories, id: category.id)
        @ToastService.success('Category successfully deleted!')
      .catch (e) =>
        @ToastService.error(e.data.error)

  $$fetchCategories: ->
    @CategoriesService.getList()
      .then (response) =>
        @categories = response.data

  submitCategoriesSort: ->
    @CategoriesService.one('sort').customPOST(ids: _.map(@categories, 'id'))
      .then =>
        @ToastService.success('Categories sorted successfully!')

