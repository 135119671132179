moment = require('moment')
_ = require('lodash')

module.exports = ->
  restrict: 'E'
  scope:
    model: '='
    onSubmit: '&?'
  template: require('./index.pug')()
  link: (scope) ->
    scope.working_days =
      sun: {}
      mon: {}
      tue: {}
      wed: {}
      thu: {}
      fri: {}
      sat: {}

    scope.submit = ->
      scope.onSubmit()

    $$parseModel = (model) ->
      _.reduce(model, (accum, hours, day) ->
        accum[day] =
          is_active: true
          from: moment(hours.from, 'hh:mm').toDate()
          to: moment(hours.to, 'hh:mm').toDate()
        accum
      , scope.working_days)

    if _.isEmpty(scope.model)
      scope.model = {}
      unwatch = scope.$watch 'model', (newValue) ->
        return if _.isEmpty(newValue)
        $$parseModel(newValue)
        unwatch()
    else
      $$parseModel(scope.model)

    $$workingDaysWatch = ->
      _.forEach scope.working_days, (day_hours, day) ->
        if day_hours.is_active
          scope.model[day] =
            from: moment(day_hours.from).format('HH:mm')
            to: moment(day_hours.to).format('HH:mm')
        else
          delete(scope.model[day])

    scope.$watch 'working_days', $$workingDaysWatch, true
