module.exports = class AreasNewContainerCtrl
  constructor: (AreasService, ToastService, $state) ->
    'ngInject'
    @AreasService = AreasService
    @ToastService = ToastService
    @$state = $state

  $onInit: ->
    @areasService = @AreasService.service(@$state.params.countryId, @$state.params.cityId)
    @area = {}

  createArea: (area) ->
    @areasService
      .post('', area)
      .then =>
        @ToastService.success('Area successfully created!')
        @$state.go('^.list')
      .catch (response) =>
        @ToastService.error(response.data.error)
