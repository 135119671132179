angular = require('angular')

module.exports =
  angular.module('App.BulkTransfers', [])
    # Container
    .component 'bulkTransfersListContainer', require('./containers/bulk_transfers_list_container/bulk-transfers-list-container')
    .component 'bulkTransferShowContainer', require('./containers/bulkTransferShowContainer/bulkTransferShowContainer')

    # UI components
    .component 'bulkTransfersList', require('./components/bulk_transfers_list/bulk-transfers-list')
    .component 'bulkTransferInfo', require('./components/bulkTransferInfo/bulkTransferInfo')

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'bulk_transfers',
          abstract: true
          url: '/bulk_transfers'
          template: '<ui-view/>'
          data:
            loginRequired: true
        .state 'bulk_transfers.list',
          url: '/?{per_page}{page}'
          component: 'bulkTransfersListContainer'
          ncyBreadcrumb:
            label: 'Bulk Transfers'
          params:
            per_page: { dynamic: true, squash: true, value: null, type: 'int' }
            page: { dynamic: true, squash: true, value: null, type: 'int' }
        .state 'bulk_transfers.show',
          url: '/:bulk_transfer_id'
          component: 'bulkTransferShowContainer'
          ncyBreadcrumb:
            label: 'Bulk Transfers'
