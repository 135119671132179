_ = require('lodash')
module.exports = ($mdSidenav, $state)->
  'ngInject'
  restrict: 'E'
  scope:
    state: '@?'
    label: '@?'
  template: require('./index.pug')()
  link: (scope) ->
    scope.closeSidenav = -> $mdSidenav('navigation').close()
    scope.goToState = ->
      return if _.isEmpty(scope.state)
      $state.go(scope.state)
    scope.classes = ->
      { active: $state.includes(@state) }
