angular = require 'angular'

module.exports = class EditCtrl
  constructor: ($state, SpecialOfferService, ToastService, ServiceService, SpecialOfferSerializer, FormDataSerializerService) ->
    'ngInject'

    @$state = $state
    @ToastService = ToastService
    @FormDataSerializerService = FormDataSerializerService
    @SpecialOfferSerializer = SpecialOfferSerializer

    @service = SpecialOfferService(@$state.params.salonId)
    @services = ServiceService.service(@$state.params.salonId).getList().$object
    @specialOffer = @service.one(@$state.params.specialOfferId).get().$object

  submit: ->
    @service.one(@$state.params.specialOfferId)
      .withHttpConfig(transformRequest: angular.identity)
      .customPUT(@specialOfferData(), '', undefined, {'Content-Type': undefined})
      .then =>
        @ToastService.success('Special offer was successfully updated!')
        @$state.go('^.list')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  specialOfferData: ->
    @FormDataSerializerService(@SpecialOfferSerializer(@specialOffer))
