module.exports = class GatewaysEditContainerCtrl
  constructor: ($state, RESTService, ToastService) ->
    'ngInject'
    @$state = $state
    @RESTService = RESTService
    @ToastService = ToastService

  $onInit: ->
    @$$fetchCategory().then => @loaded = true

  updateGateway: ->
    @gateway
      .put()
      .then (response) =>
        @ToastService.success('Gateway successfully updated!')
        @gateway = response.data
      .catch (response) =>
        @ToastService.error(response.data.error)

  $$fetchCategory: ->
    @RESTService
      .one('admin/gateways', @$state.params.gatewayId)
      .get()
      .then (response) =>
        @gateway = response.data
