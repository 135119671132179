_ = require('lodash')

module.exports = class CurrenciesContainerCtrl
  constructor: ($state, RESTService, PromptService, ToastService, CurrenciesService) ->
    'ngInject'
    @CurrenciesService = CurrenciesService
    @PromptService = PromptService
    @ToastService = ToastService
    @$state = $state

  $onInit: ->
    @query =
      order: @$state.params?.order || "code"
    @fetchCurrencies().then => @loaded = true

  fetchCurrencies: ->
    @$state.go('.', @query)
    @CurrenciesService.getList(@query)
      .then (response) =>
        @currencies = response.data
