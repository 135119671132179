require('./sms-campaigns-list.scss')

module.exports = {
  template: require('./sms-campaigns-list.pug')()
  controller: require('./sms-campaigns-list.ctrl')
  bindings: {
    smsCampaigns: '<',
    query: '<',
    total: '<',
    onFetch: '&'
  }
}
