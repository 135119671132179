_ = require('lodash')

module.exports = class ChartCtrl
  $onInit: ->
    @$$parseData(@data)

  $onChanges: (changes) ->
    @$$parseData(changes.data.currentValue) if changes.data

  $$parseData: (data) ->
    @data = if _.isArray(data)
      _.cloneDeep(data)
    else
      [_.values(_.cloneDeep(data))]

