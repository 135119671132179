module.exports = class NewTransactionContainerCtrl
  constructor: ($state, StatementRecordService, ToastService) ->
    'ngInject'
    @$state = $state
    @StatementRecordService = StatementRecordService
    @ToastService = ToastService

  $onInit: ->
    @salonId = @$state.params.salon_id

  createAdjustment: (record) ->
    @StatementRecordService.post(record)
      .then =>
        @ToastService.success('Adjustment successfully created!')
        @$state.go('^.list')
      .catch (e) =>
        @ToastService.error(e.data.error)

