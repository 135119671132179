_ = require('lodash')

module.exports = class CountriesListContainerCtrl
  constructor: ($state, ToastService, PromptService, CountriesService) ->
    'ngInject'
    @CountriesService = CountriesService
    @$state = $state
    @ToastService = ToastService
    @PromptService = PromptService

  $onInit: ->
    @countriesService = @CountriesService.service()
    @loaded = false
    @query =
      q: @$state.params?.q || ''
      order: @$state.params?.order || "countries.name"
    @fetchCountries().then => @loaded = true

  deleteCountry: (country) ->
    @PromptService
      .show(title: "Do you realy want to delete country #{country.name}?")
      .then =>
        country.remove()
      .then =>
        _.remove(@countries, id: country.id)
        @ToastService.success('Country successfully delete!')
      .catch (response) =>
        if response
          @ToastService.error(response.data.error)

  fetchCountries: ->
    @$state.go('.', @query)

    @countriesService
      .getList(@query)
      .then (response) =>
        @countries = response.data
        @total = response.headers('x-total')
