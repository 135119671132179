angular = require('angular')

module.exports = angular.module('App.notifications', [])

.controller('NewNotificationCtrl', require('./controllers/new_notification.ctrl'))
.controller('NotificationsCtrl', require('./controllers/notifications.ctrl'))
.controller('ShowNotificationCtrl', require('./controllers/show_notification.ctrl'))

.factory('NotificationService', require('./services/notification.service'))

.config(($stateProvider) ->
  'ngInject'
  $stateProvider
    .state 'notifications',
      abstract: true
      url: '/notifications'
      data:
        loginRequired: true
    .state 'notifications.list',
      controller: 'NotificationsCtrl as ctrl'
      url: '?{per_page}{page}'
      template: require('./templates/list.pug')()
      params:
        per_page: { dynamic: true, squash: true, value: 15, type: 'int' }
        page: { dynamic: true, squash: true, value: 1, type: 'int' }
      ncyBreadcrumb:
        label: 'Push Notifications'
    .state 'notifications.new',
      controller: 'NewNotificationCtrl as ctrl'
      url: '/notifications/new?{template}'
      template: require('./templates/new.pug')()
      ncyBreadcrumb:
        label: 'New Push Notification'
        parent: 'notifications.list'
    .state 'notifications.show',
      controller: 'ShowNotificationCtrl as ctrl'
      url: '/notifications/:notificationId'
      template: require('./templates/show.pug')()
      ncyBreadcrumb:
        label: 'Push Notification info'
        parent: 'notifications.list'
)
