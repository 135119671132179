angular = require('angular')

module.exports = angular.module('App.Components', [
  require('./common/common.module').name
  require('./cities/cities.module').name
  require('./areas/areas.module').name
  require('./countries/countries.module').name
  require('./gateways/gateways.module').name
  require('./bulk_transfers/bulk_transfers.module').name
  require('./appointments/appointments.module').name
  require('./users/users.module').name
  require('./clients/clients.module').name
  require('./schedule_break').name
  require('./payments').name
  require('./provinces/provinces.module').name
  require('./services').name
  require('./service_addons').name
  require('./charts').name
  require('./owner_registration_requests/owner-registration-requests.module').name
  require('./categories/categories.module').name
  require('./products/products.module').name
  require('./product_categories/product_categories.module').name
  require('./product_brands/product_brands.module').name
  require('./currencies/currencies.module').name
  require('./availability/availability.module').name
  require('./salons').name
  require('./salons_statements/salons_statements.module').name
  require('./sms_campaigns/sms_campaigns.module').name
  require('./activity_logs/activity_logs.module').name
  require('./promo_codes/promo-codes.module').name
  require('./transactions/transactions.module').name
  require('./refund_policies').name
])
