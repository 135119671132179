_ = require('lodash')
MULTIPLIERS = [604800, 86400, 3600, 60]

module.exports = class RefundPolicyFormController
  constructor: (DurationToString) ->
    'ngInject'
    @DurationToString = DurationToString

  $onInit: ->
    if @refundPolicy.active_prior_to_start
      @multiplier = _.find(MULTIPLIERS, (mul) => (@refundPolicy.active_prior_to_start / mul) > 1) || 1
      @numberValue = @refundPolicy.active_prior_to_start / @multiplier
    else
      @multiplier = 3600
      @numberValue = 1

  updateActivePriorToStart: ->
    @refundPolicy.active_prior_to_start = @numberValue * @multiplier

  presentDuration: ->
    "# => #{@DurationToString(@numberValue * @multiplier)}"
