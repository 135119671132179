module.exports = class CitiesNewContainerCtrl
  constructor: (CitiesService, ToastService, $state) ->
    'ngInject'
    @ToastService = ToastService
    @$state = $state
    @CitiesService = CitiesService

  $onInit: ->
    @citiesService = @CitiesService.service(@$state.params.countryId)
    @city = {
      search_radius_kilometers: 15
    }

  createCity: (city) ->
    @citiesService
      .post(city)
      .then (response) =>
        @ToastService.success('City successfully created!')
        @$state.go('countries.edit.cities.edit', cityId: response.data.id)
      .catch (response) =>
        @ToastService.error(response.data.error)
