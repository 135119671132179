moment = require('moment')
SERVER_DATE_TIME_FORMAT = require('app-constants').SERVER_DATE_TIME_FORMAT

NO_B2B_DATE = new Date(2017, 6, 1, 0, 1)

module.exports = class TransactionsListCtrl
  constructor: (recordableText, recordableHref) ->
    'ngInject'
    @updateTable = @updateTable.bind(@)
    @recordableText = recordableText
    @recordableHref = recordableHref

  resetPaginationAndUpdateTable: ->
    @query.page = 1
    @updateTable()

  updateTable: ->
    @onRefresh()

  revert: (record) ->
    @onRevert(record: record)

  classesFor: (record) ->
    return 'transactions-list__row--no-b2b' if moment(record.created_at, SERVER_DATE_TIME_FORMAT).isBefore(NO_B2B_DATE)
    "transactions-list__row--#{record.operation_type}"

  recordPaidTotal: (record) ->
    return record.paid_total if record.statement_recordable_type == 'Payment'
    '-'

  recordFee: (record) ->
    return record.salony_fee_amount if record.statement_recordable_type == 'Payment'
    '-'
