require('./index.scss')

module.exports =
  controller: require('./index.ctrl')
  template: require('./index.pug')()
  bindings:
    services: '<',
    warningMessagesHash: '<',
    category: '<',
    onSort: '&'
