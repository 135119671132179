module.exports = class PromoCodesEditContainerCtrl
  constructor: ($state, ToastService) ->
    'ngInject'
    @ToastService = ToastService
    @$state = $state

  updatePromoCode: (promoCode) ->
    promoCode
      .put()
      .then (response) =>
        @ToastService.success('Promo Code successfully updated!')
        @promoCode = response.data
      .catch (response) =>
        @ToastService.error(response.data.error)
