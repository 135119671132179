module.exports = class ProductBrandsEditContainerCtrl
  constructor: ($state, RESTService, FormDataSerializerService, ToastService) ->
    'ngInject'
    @$state = $state
    @RESTService = RESTService
    @FormDataSerializerService = FormDataSerializerService
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @$$fetchProductBrand().then => @loaded = true

  updateProductBrand: (productBrand) ->
    delete productBrand.image unless productBrand.image instanceof File
    productBrand
      .withHttpConfig(transformRequest: angular.identity)
      .customPUT(@FormDataSerializerService(productBrand), '', undefined, {'Content-Type': undefined})
      .then =>
        @ToastService.success('ProductBrand was successfully updated!')
        @$state.go('^.list')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  $$fetchProductBrand: ->
    @RESTService
      .one('admin/product_brands', @$state.params.productBrandId)
      .get()
      .then (response) =>
        @productBrand = response.data

