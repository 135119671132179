TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class UsersEditContainerCtrl
  constructor: ($state, RESTService, ToastService, PromptService, UsersService, $q) ->
    'ngInject'
    @$q = $q
    @ToastService = ToastService
    @RESTService = RESTService
    @userId = $state.params.userId
    @UsersService = UsersService.one(@userId)
    @PromptService = PromptService

  $onInit: ->
    @loaded = false

    @$q.all([
      @fetchCredits(),
      @fetchSalons()
    ]).then => @loaded = true

  fetchCredits: ->
    @UsersService.get().then (response) =>
      @form = response.data
      @credits = {}
      @globalCredits = {}
      for credit in @form.credits
        @assignCredit(credit.salon?.id, credit.country?.id, credit)

  fetchSalons: ->
    @RESTService.setFullResponse(true).one('admin/salons/active').get({ user_id: @userId })
      .then (response) =>
        @salons = response.data

  submit: (form) ->
    form.put()
      .then =>
        @ToastService.success(TOAST_NOTIFICATION.updated_successfully)
      .catch (response) =>
        @ToastService.error(response.data.error)

  confirm: ->
    @form.one('confirm').put()
      .then (response) =>
        @form.confirmed_at = response.data.confirmed_at
        @ToastService.success('User confirmed successfully!')
      .catch (response) =>
        if response.data
          @ToastService.error(response.data.error)

  refreshConfirmationTokenTimeout: ->
    @form.one('refresh_confirmation_token_timeout').put()
      .then (response) =>
        @form.confirmation_sent_at = response.data.confirmation_sent_at
        @form.confirmation_token_expires_in = response.data.confirmation_token_expires_in
        @ToastService.success('Confirmation code timeout has been reset successfully!')
      .catch (response) =>
        if response.data
          @ToastService.error(response.data.error)

  blockUser: ->
    @form.one('block').put()
      .then (response) =>
        @form.blocked_for_sign_in_at = response.data.blocked_for_sign_in_at
        @ToastService.success('User blocked successfully!')
      .catch (response) =>
        if response.data
          @ToastService.error(response.data.error)

  unblockUser: ->
    @form.one('unblock').put()
      .then (response) =>
        @form.blocked_for_sign_in_at = response.data.blocked_for_sign_in_at
        @ToastService.success('User unblocked successfully!')
      .catch (response) =>
        if response.data
          @ToastService.error(response.data.error)

  creditsUpdate: (salonId, amount, countryId) ->
    @UsersService.one('credits').put({ salon_id: salonId, amount: amount, country_id: countryId})
      .then (response) =>
        credit = response.data.credit
        @assignCredit(credit.salon?.id, credit.country?.id, credit)
        @ToastService.success('Credits updated successfully!')
      .catch (response) =>
        if response
          @ToastService.error(response.data.error)

  creditsDelete: (salonId, creditId, countryId) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete credit?",
      title: 'Confirm'
    ).then =>
      @UsersService.one('credits').one(creditId.toString()).remove()
        .then (response) =>
          if salonId
            delete @credits[salonId]
          else
            delete @globalCredits[countryId]
        .catch (response) =>
          if response
            @ToastService.error(response.data.error)

  assignCredit: (salonId, countryId, credit) ->
    if salonId
      @credits[salonId] = credit
    else
      @globalCredits[countryId] = credit
