module.exports = class AppointmentsShowContainerCtrl
  constructor: ($state, RESTService) ->
    'ngInject'

    @RESTService = RESTService
    @$state = $state

  $onInit: ->
    @loaded = false
    @appointmentService = @RESTService.setFullResponse(true).one('admin/appointments')
    @fetchAppointment()

  fetchAppointment: ->
    @appointment = @appointmentService
      .one(@$state.params.appointmentId)
      .get()
      .$object
    @loaded = true
