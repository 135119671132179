require('./categories-list.scss')

module.exports = {
  template: require('./categories-list.pug')()
  controller: require('./categories-list.ctrl')
  bindings:
    categories: '<'
    onCategoryDelete: '&'
    onSort: '&'
}
