require('./users-credits.scss')

module.exports = {
  template: require('./users-credits.pug')()
  controller: require('./users-credits.ctrl')
  bindings:
    credits: '<',
    globalCredits: '<',
    salons: '<',
    onCreditsUpdate: '&',
    onCreditsDelete: '&'
}
