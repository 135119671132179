module.exports = class TransactionInfoCtrl
  constructor: (recordableHref, recordableText) ->
    'ngInject'
    @recordableHref = recordableHref
    @recordableText = recordableText

  href: ->
    @recordableHref(@statementRecord)

  text: ->
    @recordableText(@statementRecord)
