require('./state-filter.scss')

module.exports =
  require:
    ngModel: '^ngModel'
  template: require('./state-filter.pug')()
  controller: require('./state-filter.ctrl')
  bindings:
    states: '<',
    selectedStates: '<',
    onFilterChange: '&'
