_ = require('lodash')

module.exports = class ProductCategoriesContainerCtrl
  constructor: (PromptService, ToastService, $state, ProductCategoriesService) ->
    'ngInject'
    @$state = $state
    @ProductCategoriesService = ProductCategoriesService
    @PromptService = PromptService
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @query =
      q: @$state.params?.q || ''
      order: @$state.params?.order || "-product_categories.created_at"
      per_page: @$state.params?.per_page || 25
      page: @$state.params?.page || 1
      rows_array: [15, 25, 50]

    @fetchProductCategories().then => @loaded = true

  deleteProductCategory: (productCategory) ->
    @PromptService
      .show(title: "Do you realy want to delete productCategory #{productCategory.name.en}?")
      .then -> productCategory.remove()
      .then =>
        _.remove(@productCategories, id: productCategory.id)
        @ToastService.success('ProductCategory successfully deleted!')
      .catch (e) =>
        @ToastService.error(e.data.error)

  fetchProductCategories: ->
    @$state.go('.', @query)

    @ProductCategoriesService
      .getList(@query)
      .then (response) =>
        @productCategories = response.data
        @total = response.headers('x-total')
