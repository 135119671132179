constants = require('app-constants')
HOME_TYPES = constants.HOME_TYPES
IN_SALON_TYPES = constants.HOME_TYPES
_ = require('lodash')

module.exports = class SalonSearchRanks
  constructor: (ToastService, RESTService, $q) ->
    'ngInject'
    @ToastService = ToastService
    @RESTService = RESTService
    @$q = $q

  $onInit: ->
    @$q.all([
      @RESTService.one('admin/categories').get()
      @salon.one('search_ranks').get()
    ]).then ([categories_response, search_rank_response]) =>
      @categories = categories_response.data
      @form = @responsesToForm(@categories, search_rank_response.data)

  submit: ->
    @salon.one('search_ranks').post(null, @formToParams(@form))
      .then () =>
        @ToastService.success('Search ranks successfully updated')

  responsesToForm: (categories, search_ranks) ->
    search_rank_cache = _.reduce(
      search_ranks.category_ranks
      , (acc, category_rank) ->
        acc[category_rank.category_id] = category_rank.rank
        acc
      , {})
    {
      search_rank: search_ranks.search_rank,
      category_ranks: _.reduce(
        categories
        , (acc, category) ->
          acc[category.id] = search_rank_cache[category.id] || 0
          acc
        , {})
    }

  formToParams: (form) ->
    {
      search_rank: form.search_rank,
      category_ranks: _.reduce(
        form.category_ranks,
        (acc, rank, category_id) ->
          acc.push(rank: rank, category_id: category_id)
          acc
        , [])
    }
