module.exports = class EditTransferInformationCtrl
  constructor: (TransferInformationService, $state, ToastService) ->
    'ngInject'
    @transferInformationService = TransferInformationService.service($state.params.salonId)
    @ToastService = ToastService


  $onInit: ->
    @transfer_information = @salon.transfer_information

  submit: ->
    @transferInformationService.post(@transfer_information)
      .then =>
        @ToastService.success('Transfer information successfully updated')
      .catch (response)=>
        @ToastService.error("Transfer information is not updated due to: #{response.data.error}")

