require('./bulk-transfers-list.scss')

module.exports =
  template: require('./bulk-transfers-list.pug')()
  controller: require('./bulk-transfers-list.ctrl')
  bindings:
    bulkTransfers: '<'
    query: '<'
    total: '<'
    onRefresh: '&'
