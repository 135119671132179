require('./product-categories-list.scss')

module.exports = {
  template: require('./product-categories-list.pug')()
  controller: require('./product-categories-list.ctrl')
  bindings:
    productCategories: '<'
    onProductCategoryDelete: '&'
    query: '<',
    total: '<',
    onRefresh: '&'
}
