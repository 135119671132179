module.exports = class CurrenciesEditContainerCtrl
  constructor: ($state, RESTService, ToastService) ->
    'ngInject'
    @$state = $state
    @RESTService = RESTService
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @$$fetchCurrency().then => @loaded = true

  updateCurrency: (currency) ->
    currency.put()
      .then =>
        @ToastService.success('Currency was successfully updated!')
        @$state.go('^.list')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)

  $$fetchCurrency: ->
    @RESTService
      .one('admin/currencies', @$state.params.currencyId)
      .get()
      .then (response) =>
        @currency = response.data

