_ = require('lodash')

module.exports = class ProductsFormCtrl
  constructor: (GetMatchesService, RESTService, ProductBrandsService, ProductCategoriesService, SalonService)->
    'ngInject'
    @GetMatchesService = GetMatchesService
    @ProductBrandsService = ProductBrandsService
    @ProductCategoriesService = ProductCategoriesService
    @SalonService = SalonService
    @RESTService = RESTService

  $onInit: ->
    @formSalons = _.clone(@product.salons) || []
    @selectedCity = @product.city
    @selectedBrand = @product.brand
    @selectedCategory = @product.category

    @title = if @isNew then 'New Product' else 'Edit Product'
    @RESTService.one('admin/supported_countries').get()
      .then (response) =>
        @cities = _.flatMap(response.data.countries, (c)-> c.cities)

  submit: ->
    salonIds = _.map @formSalons, (s)-> s.id
    params = _.clone(@product)
    params.salon_ids = salonIds
    @onSubmit(product: params)

  setProductCityId: ->
    @product.city_id = @selectedCity?.id

  matchedCities: (query) ->
    @GetMatchesService.call(@cities, query, 'name')

  setProductBrandId: ->
    @product.product_brand_id = @selectedBrand?.id

  matchedBrands: (query) ->
    @ProductBrandsService.getList(q: query).then (response)->
      response.data

  setProductCategoryId: ->
    @product.product_category_id = @selectedCategory?.id

  matchedCategories: (query) ->
    @ProductCategoriesService.getList(q: query).then (response)->
      response.data

  addSalon: ->
    if @selectedSalon
      @formSalons.push @selectedSalon

  matchedSalons: (query) ->
    @SalonService.getList(q: query).then (response) =>
      selectedIds = _.map @formSalons, (e)-> e.id
      _.filter response.data, (el) -> !selectedIds.includes(el.id)
