_ = require('lodash')

module.exports = class UsersCreditsCtrl
  constructor: (GetMatchesService, RESTService) ->
    'ngInject'
    @getMatchesService = GetMatchesService
    @RESTService = RESTService

  $onInit: ->
    @salons_amount = {}
    @globals_amount = {}
    @RESTService.one('admin/supported_countries').get().then (response) =>
      @countries = response.data.countries

    _.map @salons, (salon) ->
      salon.searchText = (salon.name || salon.ar_name).toLowerCase()
      salon

  submitSalonCredits: (salonId) ->
    @onCreditsUpdate(salonId: salonId, amount: @salons_amount[salonId], countryId: null)
    @salons_amount[salonId] = null

  submitGlobalCredits: (countryId) ->
    @onCreditsUpdate(salonId: null, amount: @globals_amount[countryId], countryId: countryId)
    @globals_amount[countryId] = null

  submitNewSalonCredits: ->
    @onCreditsUpdate(salonId: @newSalon.id, amount: @salonAmountToAdd, countryId: null)
    @newSalon = null
    @salonAmountToAdd = null

  submitNewGlobalCredits: ->
    @onCreditsUpdate(salonId: null, amount: @globalAmountToAdd, countryId: @newCountry.id)
    @newCountry = null
    @globalAmountToAdd = null

  selectableSalons: ->
    usedSalonsIds = Object.keys(@credits)
    @salons.filter(
      (salon) ->
        !usedSalonsIds.includes(salon.id.toString())
    )

  matchedSalons: (q) ->
    filteredSalons = @selectableSalons()
    if q
      filteredSalons.filter( @createFilterFor(q) )
    else
      filteredSalons

  createFilterFor: (q) ->
    regexp = new RegExp(q, 'gi')
    (salon) -> regexp.test(salon.searchText)
