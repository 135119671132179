_ = require('lodash')
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class SalonOwnerTabCtrl
  constructor: ($state, SalonService, RESTService, ToastService, PromptService) ->
    'ngInject'
    @$state = $state
    @SalonService = SalonService
    @RESTService = RESTService
    @ToastService = ToastService
    @PromptService = PromptService

    @newForm = {}
    @search_result = []
    @showNewForm = false
    @simulateQuery = false
    @salonService = @SalonService.one(@$state.params.salonId)
    @salonService.getList('owners').then (response) =>
      @owners = response.data

  addOwner: (owner) ->
    @owners.post(id: owner.value)
      .then (response) =>
        @owners.push(response.data)
        @ToastService.success('Adding was successfully!')

  updateNotificationsSettings: (owner) ->
    owner.put()
    .then =>
      @ToastService.success('Owner settings successfully updated!')

  deleteOwner: (owner) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete owner '#{owner.first_name} #{owner.last_name}'?"
    ).then =>
      owner.remove()
        .then =>
          _.remove(@owners, owner)
          @ToastService.success(TOAST_NOTIFICATION.deleted_successfully)
        .catch (response) =>
          message = response.data.error
          @ToastService.error(message)

  querySearch: (query) ->
    @RESTService.all('admin/owners/search').getList(q: query)
      .then (response) =>
        @users = response.data
        @search_result = @convertToAutocomplete(@users)

  convertToAutocomplete: (users) ->
    _.map users, (user) ->
      value: user.id
      display: "#{user.first_name} #{user.last_name}(#{user.phone_number})"
