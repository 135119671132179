require('./provinces-edit-container.scss')

module.exports = {
  template: require('./provinces-edit-container.pug')()
  controller: require('./provinces-edit-container.ctrl')
  bindings:
    country: '<'
    city: '<'
    province: '<'
}
