_ = require('lodash')

module.exports = class SalonsCtrl
  constructor: (RESTService, SalonService, ToastService, $state, PromptService, GetMatchesService) ->
    'ngInject'

    @RESTService = RESTService
    @SalonService = SalonService
    @ToastService = ToastService
    @$state = $state
    @PromptService = PromptService
    @getMatchesService = GetMatchesService

  $onInit: ->
    @query =
      order: @$state.params?.order || ''
      per_page: @$state.params?.per_page || 15
      rows_array: [15, 25, 50]
      country_id: @$state.params?.country_id
      page: @$state.params?.page || 1
      q: @$state.params?.q || ''
    @updateTable = @updateTable.bind(@)
    @updateTable()
    @RESTService.one('admin/supported_countries').get()
      .then (response) =>
        @countries = response.data.countries
        @country = _.find @countries, id: parseInt(@query.country_id)

  rowClasses: (salon) ->
    'salons__deleted': !!salon.deleted_at

  updateTable: ->
    @$state.go('.', order: @query.order, per_page: @query.per_page, page: @query.page, q: @query.q, country_id: @query.country_id)
    @SalonService
      .getList(@query)
      .then (response) =>
        @salons = response.data
        @total = response.headers('x-total')
      .catch (response) ->

  setCountryId: ->
    @query.country_id = @country?.id
    @updateTable()

  recoverSalon: (salon) ->
    @SalonService.one(salon.id).post('recover')
      .then (response) =>
        salon.deleted_at = null
        @ToastService.success('Salon successfully recovered!')

  deleteSalon: (salon) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete salon '#{salon.name}'?"
    ).then =>
      @SalonService.one(salon.id).remove()
      .then (response) =>
        _.merge(salon, response.data)
        @ToastService.success('Salon successfully deleted!')
