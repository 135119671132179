_ = require('lodash')

module.exports = class ListCtrl
  constructor: (ToastService, PromptService, RefundPolicyService, $state, DurationToString) ->
    'ngInject'
    @ToastService = ToastService
    @PromptService = PromptService
    @RefundPolicyService = RefundPolicyService
    @$state = $state
    @DurationToString = DurationToString

  $onInit: ->
    @fetchRefundPolicies()

  presentDuration: (t) ->
    @DurationToString(t)

  fetchRefundPolicies: ->
    @RefundPolicyService.service(@$state.params.salonId)
      .getList()
      .then (response) =>
        @refundPolicies = response.data

  delete: (refundPolicy) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete refund policy '#{refundPolicy.id}'?"
    ).then =>
      refundPolicy.remove()
        .then =>
          _.remove(@refundPolicies, refundPolicy)
          @ToastService.success('Refund policy deleted successfully!')
        .catch (response) =>
          message = response.data.error
          @ToastService.error(message)
