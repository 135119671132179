_ = require('lodash')

populateFormDataWith = (object, acc, rootKey) ->
  _.reduce(
    object,
    ((acc, value, key) ->
      escapedKey = if _.isEmpty(rootKey) then key else "#{rootKey}[#{key}]"

      if value instanceof Array
        if value.length
          _.forEach(value, (item) ->
            if item instanceof Object
              populateFormDataWith(item, acc, "#{escapedKey}[]")
            else
              acc.append("#{escapedKey}[]", item)
          )
        else
          acc.append("#{escapedKey}[]", [])
      else if value instanceof File
        acc.append(escapedKey, value)
      else if value instanceof Blob
        acc.append(escapedKey, value)
      else if value instanceof Date
        acc.append(escapedKey, value)
      else if value instanceof Object
        populateFormDataWith(value, acc, escapedKey)
      else
        acc.append(escapedKey, value)
      acc
    ), acc)

module.exports = ->
  (object, key = '') ->
    formData = new FormData
    populateFormDataWith(object, formData, key)
    formData
