module.exports = class SmsCampaignsCtrl
  constructor: (RESTService, $state) ->
    'ngInject'

    @RESTService = RESTService
    @$state = $state

  $onInit: ->
    @query = {
      order: @$state.params?.order || ''
      per_page: @$state.params?.per_page || 15
      rows_array: [15, 25, 50]
      page: @$state.params?.page || 1
    }

    @updateTable()

  updateTable: ->
    @$state.go('.', order: @query.order, per_page: @query.per_page, page: @query.page, q: @query.q)
    @RESTService
      .one('admin/sms_campaigns')
      .getList(null, @query)
      .then (response) =>
        @smsCampaigns = response.data
        @total = response.headers('x-total')
      .catch (response) ->
