APPOINTMENT_REFUND_TYPES = require('app-constants').APPOINTMENT_REFUND_TYPES

require('./appointments-cancel.scss')
_ = require('lodash')

module.exports = class AppointmentsCancelCtrl
  constructor: (RESTService, ToastService, PromptService) ->
    'ngInject'
    @ToastService = ToastService
    @PromptService = PromptService
    @AppointmentsCancelService = RESTService.one('admin/appointments').one(@appointment.id.toString())
    @appointmentRefundTypes = APPOINTMENT_REFUND_TYPES
    @refundWay = _.keys(@appointmentRefundTypes)[0]
    @refundPercent = @appointment.active_refund_policy_percent

  submit: ->
    @PromptService.show(
      title: 'Confirm'
      textContent: @warningTextContent()
      ok: 'Yes'
      cancel: 'No'
    ).then =>
      params = { refund_way: @refundWay }
      if @isCustomPolicy
        params.custom_refund_percent = @refundPercent
      @AppointmentsCancelService.post('cancel', params)
        .then (response) =>
          @appointment.state = 'canceled'
          @ToastService.success('Appointment successfully canceled!')
        .catch (response) =>
          @ToastService.error(response.data.error)

  isRefundPolicyApplied: ->
    @appointment.applied_cancelation_policy && @appointment.active_refund_policy_percent != 100

  warningTextContent: ->
    policySuffix = if @isCustomPolicy
      "with custom refund policy percent #{@refundPercent}%"
    else
      "with current active policy refund percent #{@appointment.active_refund_policy_percent}%"
    "Are you sure you want to refund Appointment #{@appointment.id} as #{APPOINTMENT_REFUND_TYPES[@refundWay]}, #{policySuffix}?"
