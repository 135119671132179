populateSalonTypeFor = (salon, stylist) ->
  {
    isDefault:   -> salon.type == 0
    inSalonOnly: -> salon.type == 1
    inHomeOnly:  -> [2, 3].includes(salon.type)
    showHomeWorkingHours: ->
      if this.isDefault()
        stylist.serve_home
      else
        !this.inSalonOnly()
    showWorkingHours: ->
      if this.isDefault()
        stylist.serve_salon
      else
        !this.inHomeOnly()
  }

module.exports = ->
  (salon, stylist = { serve_salon: true, serve_home: true }) ->
    populateSalonTypeFor(salon, stylist)
