angular = require('angular')

module.exports =
  angular
  .module('App.Appointments', [])
  .controller 'AppointmentsCancelCtrl', require('./components/appointments_cancel/appointments-cancel.ctrl')

  # Container
  .component 'appointmentsListContainer', require('./containers/appointments_list_container/appointments-list-container')
  .component 'appointmentsShowContainer', require('./containers/appointments_show_container/appointments-show-container')

  # UI components
  .component 'appointmentsList', require('./components/appointments_list/appointments-list')
  .component 'appointmentsShow', require('./components/appointments_show/appointments-show')

  .config ($stateProvider) ->
    'ngInject'
    $stateProvider
      .state 'appointments',
        abstract: true
        url: '/appointments'
        template: '<ui-view/>'
        data:
          loginRequired: true
      .state 'appointments.list',
        url: '?{order}{per_page}{page}{states}{q}{user_id}'
        component: 'appointmentsListContainer'
        ncyBreadcrumb:
          label: 'Appointments'
        params:
          states: { dynamic: true, squash: true, value: null, type: 'any' }
          user_id: { dynamic: true, squash: true, value: null, type: 'int' }
          q: { dynamic: true, squash: true, value: null, type: 'string' }
          order: { dynamic: true, squash: true, value: null, type: 'string' }
          per_page: { dynamic: true, squash: true, value: null, type: 'int' }
          page: { dynamic: true, squash: true, value: null, type: 'int' }
      .state 'appointments.show',
        url: '/:appointmentId'
        component: 'appointmentsShowContainer'
        ncyBreadcrumb:
          label: 'Appointment Info {{$$childHead.$ctrl.appointment.id}}'
          parent: 'appointments.list'

