angular = require('angular')
module.exports =
  angular
  .module('App.payments', [])

  .factory('PaymentService', require('./services/payment.service'))

  .config(($stateProvider) ->
    'ngInject'
    $stateProvider
      .state 'payments',
        url: '/payments'
        abstract: true
        data:
          loginRequired: true
      .state 'payments.list',
        component: 'paymentsList'
        url: '?{order}{per_page}{page}{q}{states}'
        params:
          order: { dynamic: true, squash: true, value: null, type: 'string' }
          per_page: { dynamic: true, squash: true, value: null, type: 'int' }
          page: { dynamic: true, squash: true, value: null, type: 'int' }
          q: { dynamic: true, squash: true, value: null, type: 'string' }
          states: { dynamic: true, squash: true, value: null, type: 'string' }
        ncyBreadcrumb:
          label: 'Payments'
      .state 'payments.show',
        component: 'paymentShow'
        url: '/:paymentId'
        ncyBreadcrumb:
          label: 'Payment info {{$$childHead.$ctrl.payment.id}}'
          parent: 'payments.list'
  )
