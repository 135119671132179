module.exports = class SaveCtrl
  constructor: ($state, ToastService) ->
    'ngInject'
    @$state = $state
    @ToastService = ToastService

  submit: (scheduleBreak) ->
    scheduleBreak.save()
      .then (response) =>
        @ToastService.success('Schedule break created successfully!')
        @$state.go('^.list')
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)
