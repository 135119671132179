angular = require('angular')
module.exports =
  angular
  .module('App.Core.Components.Charts', [])

  .component('chartsList', require('./charts_list'))
  .component('chart', require('./chart'))

  .config(($stateProvider) ->
    'ngInject'
    $stateProvider
      .state 'charts',
        component: 'chartsList'
        ncyBreadcrumb:
          label: 'Charts'
        url: '/charts'
        data:
          loginRequired: true
    )
