module.exports = class CitiesEditContainerCtrl
  constructor: (CitiesService, $state, ToastService) ->
    'ngInject'
    @CitiesService = CitiesService
    @ToastService = ToastService
    @$state = $state

  $onInit: ->
    @citiesService = @CitiesService.service(@$state.params.countryId).one(@$state.params.cityId)

  updateCity: (city) ->
    @citiesService.customPUT(city)
      .then (response) =>
        @ToastService.success('City successfully updated!')
        @city = response.data
      .catch (response) =>
        @ToastService.error(response.data.error)
