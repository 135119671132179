require('./products-list.scss')

module.exports = {
  template: require('./products-list.pug')()
  controller: require('./products-list.ctrl')
  bindings:
    products: '<'
    onProductDelete: '&'
    query: '<',
    total: '<',
    onRefresh: '&'
}
