module.exports = class OwnerRegistrationRequestsListCtrl
  $onInit: ->
    @updateTable = @updateTable.bind(@)

  updateTable: ->
    @onRefresh()

  mark: (registrationRequest) ->
    @onRegistrationRequestMarked({registrationRequest})

  rowClasses: (registrationRequest) ->
    'owner-registration-requests-list__row_not-completed': !registrationRequest.completed

  userEditLinkLabelContent: (registrationRequest) ->
    '(' + registrationRequest.phone_country_code + ') ' + registrationRequest.phone_number

