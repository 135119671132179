module.exports = class ProductCategoriesNewContainerCtrl
  constructor: (RESTService, ToastService, $state, FormDataSerializerService) ->
    'ngInject'
    @RESTService = RESTService
    @ToastService = ToastService
    @$state = $state
    @FormDataSerializerService = FormDataSerializerService

  createProductCategory: (productCategory) ->
    @RESTService
      .one('admin/product_categories')
      .withHttpConfig(transformRequest: angular.identity)
      .customPOST(@FormDataSerializerService(productCategory), '', undefined, {'Content-Type': undefined})
      .then =>
        @ToastService.success('Product Category successfully created!')
        @$state.go('^.list')
      .catch (e) =>
        @ToastService.error(e.data.error)
