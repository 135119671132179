angular = require('angular')

module.exports = angular.module('App.notifications_campaigns', [])

.controller('notifications_campaigns.ListCtrl', require('./controllers/list.ctrl'))
.controller('notifications_campaigns.ModalCtrl', require('./controllers/modal.ctrl'))
.controller('notifications_campaigns.NewCtrl', require('./controllers/new.ctrl'))
.controller('notifications_campaigns.ShowCtrl', require('./controllers/show.ctrl'))

.factory('NotificationsCampaignService', require('./services/notifications_campaign.service'))

.config(($stateProvider) ->
  'ngInject'
  $stateProvider
    .state 'notifications_campaigns',
      abstract: true
      url: '/notifications_campaigns'
      template: '<ui-view>'
      data:
        loginRequired: true
    .state 'notifications_campaigns.list',
      controller: 'notifications_campaigns.ListCtrl as ctrl'
      url: '/list'
      template: require('./templates/list.pug')()
      ncyBreadcrumb:
        label: 'Push Notification Campains'
    .state 'notifications_campaigns.new',
      controller: 'notifications_campaigns.NewCtrl as ctrl'
      url: '/new'
      template: require('./templates/new.pug')()
      ncyBreadcrumb:
        label: 'New Push Notification Campain'
        parent: 'notifications_campaigns.list'

    .state 'notifications_campaigns.show',
      controller: 'notifications_campaigns.ShowCtrl as ctrl'
      url: '/:id'
      template: require('./templates/show.pug')()
      ncyBreadcrumb:
        label: 'Push Notification Campain Info'
        parent: 'notifications_campaigns.list'

)
