module.exports = class SaveServiceCtrl
  constructor: ($state, $mdDialog, ToastService, ServiceService) ->
    'ngInject'
    @$state = $state
    @$mdDialog = $mdDialog
    @ToastService = ToastService
    @ServiceService = ServiceService

  submit: (service) ->
    service.save()
      .then (response) =>
        @ToastService.success('Service saved successfully!')
        @$state.go('salons.edit.services.edit', serviceId: response.data.id)
      .catch (response) =>
        @ToastService.error(response.data.error)

  stylistsUpdate: (stylistIds) ->
    @service.stylist_ids = stylistIds
    @submit(@service)

  duplicateService: ->
    @$mdDialog.show(
      bindToController: true
      controller: 'ServicesDuplicateCtrl'
      template: require('../services_duplicate/services-duplicate.pug')()
      controllerAs: 'ctrl'
      locals: { service: @service, salonId: @$state.params.salonId }
      clickOutsideToClose: true)
