RESPONSIBILITY_SIDES = require('app-constants').RESPONSIBILITY_SIDES

module.exports = class PromoCodesFormCtrl
  constructor: ->
    'ngInject'

  submit: ->
    @onSubmit(promoCode: @promoCode)

  responsibilitySides: ->
    RESPONSIBILITY_SIDES

  capitalize: (string) ->
    string.charAt(0).toUpperCase() + string.substr(1).toLowerCase()
