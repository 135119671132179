angular = require('angular')

module.exports =
  angular.module('App.SalonsStatements', [])
    .component('salonsStatementsPayments', require('./containers/payments/payments'))
    .component('salonsStatementsPaymentsList', require('./components/payments_list/payments-list'))

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'salons_statements',
          abstract: true
          url: '/salons_statements/:salonId'
          template: '<ui-view/>'
          data:
            loginRequired: true
          ncyBreadcrumb:
            label: 'Salons Statements'
            parent: 'salons.edit'
          resolve:
            salon: (SalonService, $stateParams) ->
              'ngInject'

              SalonService
                .one($stateParams.salonId)
                .get()
                .then (response) -> response.data

        .state 'salons_statements.payments',
          url: '?{from}{to}'
          component: 'salonsStatementsPayments'
          ncyBreadcrumb:
            label: 'PDF generator'
          params:
            q: { dynamic: true, squash: true, value: null, type: 'string' }
            order: { dynamic: true, squash: true, value: null, type: 'string' }
