USER_STEP = 'user'
SALON_STEP = 'salon'
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class OwnerRegistrationRequestsFormContainerCtrl
  constructor: (RESTService, ToastService, $state, UsersService, SalonService) ->
    'ngInject'
    @RESTService = RESTService
    @ToastService = ToastService
    @UsersService = UsersService
    @SalonService = SalonService
    @$state = $state

  $onInit: ->
    @step = USER_STEP
    @RegistrationRequests = @RESTService.setFullResponse(true).service('admin/owner/registration_requests')
    @loaded = false
    @fetchRegistrationRequest()
      .then =>
        @loaded = true

  fetchRegistrationRequest: ->
    @RegistrationRequests
      .one(@$state.params.registrationRequestId)
      .get()
      .then (response) =>
        @registrationRequest = response.data

        return @$$goToList() if @registrationRequest.completed

        @step = SALON_STEP if @registrationRequest.user?.id
        [first_name, last_name] = @registrationRequest.name.split(' ')
        @userForm =
          phone_country_code: @registrationRequest.phone_country_code
          phone_number: @registrationRequest.phone_number
          first_name: first_name
          last_name: last_name
          email: @registrationRequest.email
          role: 'common'
        @salonForm =
          name: @registrationRequest.salon_name
          ar_name: @registrationRequest.salon_name
          home_service_lead_time: 60
          lead_time: 15
          is_approved: false

  createUser: (form) ->
    @UsersService
      .post(form)
      .then (response) =>
        @ToastService.success(TOAST_NOTIFICATION.created_successfully)
        @step = SALON_STEP
      .catch (response) =>
        @ToastService.error(response.data.error)

  createSalon: (form) ->
    @SalonService
      .post(form)
      .then (response) =>
        @ToastService.success(TOAST_NOTIFICATION.created_successfully)
        @mark(response.data.id)
      .catch (response) =>
        @ToastService.error(response.data.error)

  mark: (salonIdToRedirectTo)->
    @registrationRequest
      .post('mark_completed')
      .then (response) =>
        @ToastService.success('Registration request marked as completed!')
        @$state.go('salons.edit', salonId: salonIdToRedirectTo)

