angular = require('angular')
module.exports =
  angular.module('App.salons.stylists', [
    require('./modules/schedule_breaks').name
  ])

  .controller('App.salons.stylists.ListCtrl', require('./controllers/list.ctrl'))
  .controller('App.salons.stylists.NewCtrl', require('./controllers/new.ctrl'))
  .controller('App.salons.stylists.EditCtrl', require('./controllers/edit.ctrl'))
  .controller('App.salons.stylists.ScheduleBreaksCtrl', require('./controllers/schedule_breaks.ctrl'))

  .directive('stylistForm', require('./directives/stylist_form'))
  .component('stylistServices', require('./modules/stylist_services/stylist-services'))

  .factory('StylistService', require('./services/stylist.service'))
  .factory('SalonTypesService', require('./services/salon_types.service'))

  .config(($stateProvider) ->
    'ngInject'
    $stateProvider
      .state 'salons.edit.stylists',
        url: '/stylists'
        abstract: true
        template: "<ui-view/>"
      .state 'salons.edit.stylists.list',
        url: ''
        controller: 'App.salons.stylists.ListCtrl'
        controllerAs: 'list_ctrl'
        template: require('./templates/list.pug')()
        ncyBreadcrumb:
          label: 'Stylists'
      .state 'salons.edit.stylists.new',
        url: '/new'
        controller: 'App.salons.stylists.NewCtrl'
        controllerAs: 'new_ctrl'
        template: require('./templates/new.pug')()
        ncyBreadcrumb:
          label: 'New Stylist'
          parent: 'salons.edit.stylists.list'
      .state 'salons.edit.stylists.edit',
        url: '/:stylistId/edit'
        controller: 'App.salons.stylists.EditCtrl'
        controllerAs: 'edit_ctrl'
        ncyBreadcrumb:
          label: '{{edit_ctrl.stylist.name}}'
          parent: 'salons.edit.stylists.list'
        resolve:
          stylistRespose: (StylistService, $stateParams) ->
            'ngInject'
            StylistService($stateParams.salonId).one($stateParams.stylistId).get()
        template: require('./templates/edit.pug')())
