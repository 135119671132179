require('./json-editor.scss')

module.exports = {
  template: require('./json-editor.pug')()
  controller: require('./json-editor.ctrl')
  require:
    ngModel: 'ngModel'
  bindings:
    readOnly: '<'
}
