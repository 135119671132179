angular = require('angular')

module.exports =
  angular
    .module('App.Components.OwnerRegistrationRequests', [])

    .component('ownerRegistrationRequestsContainer', require('./containers/owner_registration_requests_container/owner-registration-requests-container'))
    .component('ownerRegistrationRequestsFormContainer', require('./containers/owner_registration_requests_form_container/owner-registration-requests-form-container'))

    .component('ownerRegistrationRequestsList', require('./components/owner_registration_requests_list/owner-registration-requests-list'))

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state('owner_registration_requests',
          url: '/owner_registration_requests'
          abstract: true
          template: '<ui-view></ui-view>'
          data:
            loginRequired: true)
        .state('owner_registration_requests.list',
          url: '/?{order}{per_page}{page}{q}'
          component: 'ownerRegistrationRequestsContainer'
          ncyBreadcrumb:
            label: 'Owner Registration Requests'
          params:
            order: { dynamic: true, squash: true, value: null, type: 'string' }
            per_page: { dynamic: true, squash: true, value: null, type: 'int' }
            page: { dynamic: true, squash: true, value: null, type: 'int' }
            q: { dynamic: true, squash: true, value: null, type: 'string' })
        .state('owner_registration_requests.fill_form',
          url: '/:registrationRequestId/fill_form'
          ncyBreadcrumb:
            label: 'Completing Registration Request'
            parent: 'owner_registration_requests.list'
          component: 'ownerRegistrationRequestsFormContainer')
