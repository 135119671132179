module.exports = class TransactionShowContainerCtrl
  constructor: (StatementRecordService, $state, revertRecordWithPrompt) ->
    'ngInject'
    @StatementRecordService = StatementRecordService
    @$state = $state
    @revertRecordWithPrompt = revertRecordWithPrompt

  $onInit: ->
    @statementRecord = @StatementRecordService.get(@$state.params.statementRecordId).$object

  handleRevert: ->
    @revertRecordWithPrompt(@statementRecord).then =>
      @$state.go('^.list')

