angular = require('angular')

module.exports =
  angular.module('App.SalonClients', [])
    # Container
    .component 'clientsListContainer', require('./containers/clients_list_container/clients-list-container')

    # UI components
    .component 'clientsList', require('./components/clients_list/clients-list')

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'salons.edit.clients',
          abstract: true
          url: '/clients'
          template: '<ui-view/>'
          data:
            loginRequired: true
        .state 'salons.edit.clients.list',
          url: '?{order}{per_page}{page}{q}'
          component: 'clientsListContainer'
          ncyBreadcrumb:
            label: 'Clients'
          params:
            order: { dynamic: true, squash: true, value: null, type: 'string' }
            per_page: { dynamic: true, squash: true, value: null, type: 'int' }
            page: { dynamic: true, squash: true, value: null, type: 'int' }
            q: { dynamic: true, squash: true, value: null, type: 'string' }
