require('./styles/login_form.scss')
require('./styles/breadcrumbs.scss')

angular = require('angular')
moment = require('moment')
SERVER_DATE_FORMAT = require('app-constants').SERVER_DATE_FORMAT
require('angular-material')
require('restangular')
require('@uirouter/angularjs')
require('angular-cookies')
require('angular-material-data-table')
require('angular-simple-logger')
require('ng-file-upload')
window._ = require('lodash')
window.Flow = require('@flowjs/ng-flow/dist/ng-flow-standalone')
require('angular-google-maps')
require('angular-animate')
require('angular-drag-and-drop-lists')
require('angular-loading-bar')
require('angular-chart.js')
require('angular-breadcrumb')
require('ngPrint')

module.exports = angular.module('App.core', [
  'ngMaterial'
  'restangular'
  'ui.router'
  'ngCookies'
  'md.data.table'
  'uiGmapgoogle-maps'
  'flow'
  'flow.provider'
  'ngAnimate'
  'dndLists'
  'ngFileUpload'
  'angular-loading-bar'
  'chart.js'
  'ncy-angular-breadcrumb'
  'ngPrint'
  require('angular-sanitize')
])

.directive('areasSelect', require('./directives/areas_select'))
.directive('uploadImage', require('./directives/upload_image'))
.directive('workingHoursFields', require('./directives/working_hours'))
.directive('adminMenu', require('./directives/admin_menu'))
.directive('adminMenuItem', require('./directives/admin_menu_item'))
.directive('stateTab', require('./directives/state_tab'))

.component('jsonEditor', require('./components/json_editor/json-editor'))
.component('dateTimeInput', require('./components/date_time_input/date-time-input'))

.factory('RESTService', require('./services/REST.service'))
.factory('CountryCodeService', require('./services/country_code.service'))
.factory('GetMatchesService', require('./services/get_matches.service'))
.factory('ToastService', require('./services/toast.service'))
.factory('PromptService', require('./services/prompt.service'))
.factory('FormDataSerializerService', require('./services/form_data_serializer.service'))
.factory('RoundTime', require('./services/round_time.service'))
.factory('DurationToString', require('./services/duration_to_string.service'))
.factory('SalonScheduleBreakService', require('./services/salon_schedule_break.service'))
.factory('StylistScheduleBreakService', require('./services/stylist_schedule_break.service'))
.factory('CategoriesService', require('./services/categories.service'))
.factory('ProductsService', require('./services/products.service'))
.factory('ProductImagesService', require('./services/product_images.service'))
.factory('ProductBrandsService', require('./services/product_brands.service'))
.factory('ProductCategoriesService', require('./services/product_categories.service'))
.factory('CurrenciesService', require('./services/currencies.service'))
.factory('ServiceService', require('./services/service.service'))
.factory('TransferInformationService', require('./services/transfer_informations.service'))
.factory('StatementRecordService', require('./services/statement_records.service'))
.factory('TransferService', require('./services/transfers.service'))
.factory('BulkTransferService', require('./services/bulk_transfers.service'))

.factory('RefundPolicyService', require('./services/refund_policy.service'))

.config(($stateProvider, $urlRouterProvider, $breadcrumbProvider, $mdDateLocaleProvider) ->
  'ngInject'
  $breadcrumbProvider.setOptions(template: require('./templates/breadcrumbs.pug')())
  $urlRouterProvider.otherwise '/'
  $stateProvider
    .state 'bad_request',
      url: '/bad_request'
      template: require('./templates/bad_request.pug')()
  $mdDateLocaleProvider.parseDate = (dateString) ->
    m = moment(dateString, SERVER_DATE_FORMAT, true)
    if m.isValid() then m.toDate() else new Date(NaN)
  $mdDateLocaleProvider.formatDate = (date) ->
    m = moment(date)
    if m.isValid() then m.format(SERVER_DATE_FORMAT) else ''
)

.run((RESTService, $state, $rootScope, $timeout, $templateCache) ->
  'ngInject'
  $rootScope.$on '$stateChangeStart', (event, toState, toParams, fromState, fromParams) ->
    RESTService.handleAccess(event, toState, toParams, fromState, fromParams)
  if RESTService.isAuthorized()
    RESTService.updateRestangularSettings()
  $rootScope.session = RESTService
  $templateCache.put(
    '/salons/templates/deposit_policy.html',
    require('../modules/salons/templates/deposit_policy.pug')())
  $templateCache.put(
    '/salons/templates/contacts.html',
    require('../modules/salons/templates/contacts.pug')())
)
