_ = require('lodash')

module.exports = class StylistsCtrl
  constructor: (SalonService, $stateParams) ->
    'ngInject'
    @SalonService = SalonService
    @$stateParams = $stateParams

  $onInit: ->
    @workingStylists = {}
    _.forEach @stylistIds, (id) =>
      @workingStylists[id] = true

    @SalonService.one(@$stateParams.salonId).getList('stylists').then (response) =>
      @stylists = response.data

  submit: ->
    stylistIds = _(@workingStylists).pickBy((v) -> v).keys().value()
    @onSubmit(stylistIds: stylistIds)
