APPOINTMENT_STATES_ALLOWED_TO_CANCEL = require('app-constants').APPOINTMENT_STATES_ALLOWED_TO_CANCEL

module.exports = class AppointmentsShowCtrl
  constructor: ($mdDialog) ->
    'ngInject'
    @$mdDialog = $mdDialog

  allowedToCancel: ->
    APPOINTMENT_STATES_ALLOWED_TO_CANCEL.includes(@appointment.state)

  cancel: ->
    @$mdDialog.show(
      bindToController: true
      controller: 'AppointmentsCancelCtrl'
      template: require('../appointments_cancel/appointments-cancel.pug')()
      controllerAs: 'ctrl'
      locals: { appointment: @appointment }
      clickOutsideToClose: true)
