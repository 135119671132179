angular = require('angular')

module.exports =
  angular
  .module('App.Currencies', [])

  .component('currenciesContainer', require('./containers/currencies_container/currencies-container'))
  .component('currenciesEditContainer', require('./containers/currencies_edit_container/currencies-edit-container'))
  .component('currenciesNewContainer', require('./containers/currencies_new_container/currencies-new-container'))

  .component('currenciesList', require('./components/currencies_list/currencies-list'))
  .component('currenciesForm', require('./components/currencies_form/currencies-form'))

  .config ($stateProvider) ->
    'ngInject'

    $stateProvider
      .state 'currencies',
        url: '/currencies'
        abstract: true
        data:
          loginRequired: true
      .state 'currencies.list',
        url: '?{order}'
        component: 'currenciesContainer'
        ncyBreadcrumb:
          label: 'Currencies'
        params:
          order: { dynamic: true, squash: true, value: null, type: 'string' }
      .state 'currencies.new',
        url: '/new'
        component: 'currenciesNewContainer'
        ncyBreadcrumb:
          label: 'New Currency'
          parent: 'currencies.list'
      .state 'currencies.edit',
        url: '/:currencyId'
        component: 'currenciesEditContainer'
        ncyBreadcrumb:
          label: '{{$$childHead.$ctrl.currency.name.en}}'
          parent: 'currencies.list'
