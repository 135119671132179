ROLES = require('app-constants').ROLES

module.exports = class UsersFormCtrl
  constructor: ($interval) ->
    'ngInject'
    @ROLES = ROLES
    @$interval = $interval

  $onInit: ->
    @initTimer()

  $onDestroy: ->
    @$inteval.cancel(@stop)
    
  initTimer: ->
    return unless @form.confirmation_token
    @stop = @$interval(
      =>
        if (@form.confirmation_token_expires_in > 0)
          @form.confirmation_token_expires_in -= 1
      ,
      1000
    )
