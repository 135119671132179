_ = require('lodash')

module.exports = (SalonTypesService) ->
  'ngInject'
  restrict: 'E'
  scope:
    salon: '='
    stylist: '='
    buttons: '='
    onSubmit: '&?'
    onDelete: '&?'
  template: require('./index.pug')()
  controller: require('./index.ctrl')
  link: (scope) ->
    $$showStylistHours = false
    scope.onSubmit ||= _.noop
    scope.onDelete ||= _.noop

    scope.isDefaultSalonType = ->
      SalonTypesService(scope.salon).isDefault()

    scope.inHomeSalonType = ->
      SalonTypesService(scope.salon).inHomeOnly()

    scope.inSalonType = ->
      SalonTypesService(scope.salon).inSalonOnly()

    scope.toogleHours = ->
      $$showStylistHours = !$$showStylistHours

    scope.isHoursShown = -> $$showStylistHours

    scope.textOnHoursButton= ->
      if !$$showStylistHours
        'Show hours'
      else
        'Hide hours'

    scope.submit = ->
      scope.onSubmit()

    scope.delete = ->
      scope.onDelete()

    scope.showHomeLeadTime = ->
      scope.isDefaultSalonType() || scope.inHomeSalonType()

    scope.showLeadTime = ->
      scope.isDefaultSalonType() || scope.inSalonType()
