angular = require('angular')
module.exports =
  angular
  .module('App.Core.Components.Common', [])

  .component('app', require('./app/app'))
  .component('stateFilter', require('./state_filter/state-filter'))
  .component('loginForm', require('./login_form'))
  .component('countryCodeSelect', require('./country_code_select'))
  .component('salonsAutocomplete', require('./components/salonsAutocomplete/salonsAutocomplete'))
  .component('usersAutocomplete', require('./components/usersAutocomplete/usersAutocomplete'))
  .component('csvTable', require('./components/csvTable/csvTable'))
  .component('fieldValue', require('./components/fieldValue/fieldValue'))

  .config ($stateProvider) ->
    'ngInject'
    $stateProvider
      .state 'login',
        url: '/login'
        ncyBreadcrumb:
          label: 'Admin Login'
        component: 'loginForm'
