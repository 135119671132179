require('./appointments-list.scss')

module.exports = {
  template: require('./appointments-list.pug')()
  controller: require('./appointments-list.ctrl')
  bindings:
    query: '<',
    user: '<',
    appointments: '<',
    states: '<',
    total: '<',
    onRefresh: '&'
}
