_ = require('lodash')

module.exports = class ServiceAddonsCtrl
  constructor: (ToastService, PromptService) ->
    'ngInject'
    @ToastService = ToastService
    @PromptService = PromptService

  $onInit: ->
    @serviceAddons = @service.getList('service_addons').$object
    @innerState = 'list'

  openNewForm: ->
    @innerState = 'new'

  openEditForm: (id) ->
    @innerState = 'edit'
    @editServiceAddon = _.find @serviceAddons, id: id

  backToList: ->
    @innerState = 'list'

  delete: (serviceAddon) ->
    @PromptService.show(
      title: "Are you sure you want to delete service addon '#{serviceAddon.name}'?")
    .then =>
      @service.one('service_addons', serviceAddon.id).remove()
        .then =>
          _.remove @serviceAddons, serviceAddon
          @ToastService.success('Service addon successfully deleted!')

  update: (serviceAddon) ->
    @service
      .one('service_addons', serviceAddon.id)
      .customPUT(serviceAddon)
      .then (response) =>
        @ToastService.success('Service addon successfully added!')
        _.merge @editServiceAddon, response.data
        @innerState = 'list'
      .catch (response) =>
        @ToastService.error(response.data.error)


  create: (form) ->
    @service.customPOST(form, 'service_addons')
      .then (response) =>
        @ToastService.success('Service addon successfully added!')
        @serviceAddons.push(response.data)
        @innerState = 'list'
      .catch (response) =>
        @ToastService.error(response.data.error)
