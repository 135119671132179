module.exports = {
  template: require('./users-list.pug')()
  controller: require('./users-list.ctrl')
  bindings:
    query: '<',
    users: '<',
    total: '<',
    onRefresh: '&',
    onUserDelete: '&'
}
