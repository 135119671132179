module.exports = class SaveRefundPolicyCtrl
  constructor: ($state, ToastService) ->
    'ngInject'
    @$state = $state
    @ToastService = ToastService

  submit: (refundPolicy) ->
    refundPolicy
      .save()
      .then (response) =>
        @ToastService.success('Refund policy saved successfully!')
        @$state.go('salons.edit.refund_policies.edit', refundPolicyId: response.data.id)
      .catch (response) =>
        @ToastService.error(response.data.error)
