angular = require('angular')
_ = require('lodash')

module.exports = class ActivityLogCtrl
  constructor: ($state, ToastService) ->
    'ngInject'
    @$state = $state
    @ToastService = ToastService

  $onInit: ->
    @loaded = false
    @fetchActivityLog = @fetchActivityLog.bind(@)
    @fetchActivityLog()

  fetchActivityLog: ->
    @$state.go('.')
    @trackedObject.one('time_states').get().then (response) =>
      @activityLog = response.data.time_states
      @loaded = true

  switch_to: (time_state) ->
    @trackedObject.one('switch_to').put(version: time_state.version)
      .then =>
        @ToastService.success('successfully reverted!')
        @fetchActivityLog()
      .catch (response) =>
        if response
          @ToastService.error(response.data.error)
