moment = require('moment')
_ = require('lodash')

module.exports = class ChartsCtrl
  constructor: (RESTService) ->
    'ngInject'
    @RESTService = RESTService

  $onInit: ->
    @startDate = moment().startOf('month').toDate()
    @endDate = moment().endOf('month').toDate()
    @chartData = {}
    @updateChartData().then => @loaded = true

  updateChartData: ->
    @RESTService.one('admin/chart')
      .get(start_date: @$$dateFormat(@startDate), end_date: @$$dateFormat(@endDate))
      .then (response) =>
        @chartData = response.data

  labels: _.memoize(
    (->
      result = []
      m = moment(@startDate)
      while m.isBefore(@endDate)
        result.push(@$$dateFormat(m))
        m.add(1, 'days')
      result
    ),
    (-> @$$cacheKey()))

  $$cacheKey: ->
    "#{@startDate}#{@endDate}"


  $$dateFormat: (date) ->
    moment(date).format('YYYY-MM-DD')
