angular = require('angular')
_ = require('lodash')
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class ReviewsCtrl
  constructor: ($state, ReviewService, ToastService,  $mdDialog, PromptService) ->
    'ngInject'
    @$state = $state
    @ReviewService = ReviewService
    @ToastService = ToastService
    @$mdDialog = $mdDialog
    @PromptService = PromptService

  $onInit: ->
    @loaded = false
    @query =
      per_page: @$state.params?.per_page || 15
      rows_array: [15, 25, 50]
      page: @$state.params?.page || 1
    @fetchReviews = @fetchReviews.bind(@)
    @fetchReviews(@query)

  fetchReviews: ->
    @$state.go('.', per_page: @query.per_page, page: @query.page)
    @ReviewService(@$state.params.salonId).getList(@query).then (response) =>
      @reviews = response.data
      @total = response.headers('x-total')
      @loaded = true

  delete: (review) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete review of '#{review.user.nickname}'?"
    ).then =>
      review.remove()
        .then =>
          _.remove(@reviews, review)
          @ToastService.success(TOAST_NOTIFICATION.deleted_successfully)
            .catch (response) =>
          message = response.data.error
          @ToastService.error(message)

  cancel: ->
    @showNewForm = false
