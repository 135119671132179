require('./usersAutocomplete.scss')

module.exports = {
  template: require('./usersAutocomplete.pug')()
  controller: require('./usersAutocomplete.ctrl')
  require:
    ngModel: 'ngModel'
  bindings:
    ngRequired: '<'
    user: '<'
}
