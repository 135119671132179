moment = require('moment')

module.exports = class AvailabilityTimesCtrl
  constructor: ->
    'ngInject'

  formatTime: (time) ->
    moment(time, 'YYYY-MM-DD HH:mm:ss Z').format('YYYY-MM-DD HH:mm')

  classesFor: (errors) ->
    [
      'availability-times__error': errors?.length
      'availability-times__valid': !errors?.length
    ]
