require('./cities-edit-container.scss')
angularModule = require('../../cities.module')

module.exports = {
  template: require('./cities-edit-container.pug')()
  controller: require('./cities-edit-container.ctrl')
  bindings:
    country: '<'
    city: '<'
}
