module.exports = class SalonsAutocompleteCtrl
  constructor: (SalonService, $timeout) ->
    'ngInject'
    @SalonService = SalonService
    @$timeout = $timeout

  querySearchSalon: () ->
    @SalonService.getList(q: @query)
      .then (response) => @$$convertToSalonAutocomplete(response.data)

  $onInit: ->
    @$timeout =>
      @salon = { value: @ngModel.$viewValue, display: @ngModel.$viewValue } if @ngModel.$viewValue

  onChange: ->
    @ngModel.$setViewValue(@salon?.value)

  $$convertToSalonAutocomplete: (salons) ->
    _.map salons, (salon) ->
      value: salon.id
      display: "#{salon.name}(#{salon.phone})"
