module.exports = class ListCtrl
  constructor: (NotificationsCampaignService, $scope) ->
    'ngInject'

    @query =
      order: ""
      per_page: 15
      page: 1
      rows_array: [15, 25, 50]
    @campaigns = []

    $scope.updateTable = =>
      NotificationsCampaignService
        .getList(@query)
        .then (response) =>
          @campaigns = response.data
          @total = response.headers('x-total')


    $scope.updateTable()

