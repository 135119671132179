angular = require('angular')
module.exports =
  angular.module('App.salons.stylists.scheduleBreaks', [])

  .config(($stateProvider) ->
    'ngInject'
    $stateProvider
    .state 'salons.edit.stylists.edit.schedule_breaks',
      url: '/schedule_breaks'
      abstract: true
      resolve:
        scheduleBreakService: (StylistScheduleBreakService, $stateParams) ->
          'ngInject'
          StylistScheduleBreakService($stateParams.salonId, $stateParams.stylistId)
      views:
        editStylistContainer:
          template: '<ui-view>'
    .state 'salons.edit.stylists.edit.schedule_breaks.list',
      url: '/'
      component: 'scheduleBreaksList'
      ncyBreadcrumb:
        label: 'Schedule breaks'
    .state 'salons.edit.stylists.edit.schedule_breaks.new',
      url: '/new'
      component: 'scheduleBreakSave'
      ncyBreadcrumb:
        label: 'New schedule break'
      resolve:
        scheduleBreak: (StylistService, RESTService, $stateParams) ->
          'ngInject'
          RESTService.restangularizeElement(
            StylistService($stateParams.salonId).one($stateParams.stylistId), {}, 'schedule_breaks')

    .state 'salons.edit.stylists.edit.schedule_breaks.edit',
      url: '/:scheduleBreakId'
      component: 'scheduleBreakSave'
      ncyBreadcrumb:
        label: '{{$$childHead.$ctrl.scheduleBreak.name}}'
        parent: 'salons.edit.stylists.edit.schedule_breaks.list'
      resolve:
        scheduleBreak: (scheduleBreakService, $stateParams) ->
          'ngInject'
          scheduleBreakService.one($stateParams.scheduleBreakId).get()
          .then (response) ->
            response.data)
