angular = require('angular')

module.exports =
  angular.module('App.Areas', [])
    .factory 'AreasService', require('./services/areas.service')
    # Container
    .component 'areasTableContainer', require('./containers/areas_list_container/areas-list-container')
    .component 'areasEditContainer', require('./containers/areas_edit_container/areas-edit-container')
    .component 'areasNewContainer', require('./containers/areas_new_container/areas-new-container')

    # UI components
    .component 'areasList', require('./components/areas_list/areas-list')
    .component 'areaForm', require('./components/area_form/area-form')

    .config ($stateProvider) ->
      'ngInject'
      $stateProvider
        .state 'countries.edit.cities.edit.areas',
          url: '/areas'
          abstract: true
          views : {
            '@': {
              template: '<ui-view/>'
            },
          }
        .state 'countries.edit.cities.edit.areas.list',
          url: '?{order}'
          component: 'areasTableContainer'
          ncyBreadcrumb:
            label: 'Areas'
          params:
            order: { dynamic: true, squash: true, value: null, type: 'string' }
        .state 'countries.edit.cities.edit.areas.new',
          url: '/new'
          component: 'areasNewContainer'
          ncyBreadcrumb:
            label: 'New Area'
            parent: 'countries.edit.cities.edit.areas.list'
        .state 'countries.edit.cities.edit.areas.edit',
          url: '/:areaId'
          component: 'areasEditContainer'
          ncyBreadcrumb:
            label: '{{$$childHead.$ctrl.area.name}}'
            parent: 'countries.edit.cities.edit.areas.list'
