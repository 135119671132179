module.exports = class ProvincesNewContainerCtrl
  constructor: (ProvincesService, ToastService, $state) ->
    'ngInject'
    @ProvincesService = ProvincesService
    @ToastService = ToastService
    @$state = $state

  $onInit: ->
    @provincesService = @ProvincesService.service(@$state.params.countryId, @$state.params.cityId)
    @province = {}

  createProvince: (province) ->
    @provincesService
      .post(province)
      .then =>
        @ToastService.success('Province successfully created!')
        @$state.go('^.list')
      .catch (response) =>
        @ToastService.error(response.data.error)

