config =
  development: require('./environment/development')
  staging: require('./environment/staging')
  production: require('./environment/production')
  test: require('./environment/test')
  preproduction: require('./environment/preproduction')

serverEnv = process.env.SERVER_ENV
console.log(serverEnv)
module.exports = config[serverEnv] || config.development
