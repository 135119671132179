angular = require('angular')
module.exports = 'App.salons.specialOffers'
angular.module('App.salons.specialOffers', [])

.controller('App.salons.specialOffers.ListCtrl', require('./controllers/list.ctrl'))
.controller('App.salons.specialOffers.NewCtrl', require('./controllers/new.ctrl'))
.controller('App.salons.specialOffers.EditCtrl', require('./controllers/edit.ctrl'))

.directive('specialOfferForm', require('./directives/special_offer_form'))

.factory('SpecialOfferService', require('./services/special_offers.service'))
.factory('SpecialOfferSerializer', require('./services/special_offers.serializer'))

.config(($stateProvider) ->
  'ngInject'
  $stateProvider
    .state 'salons.edit.special_offers',
      url: '/special_offers'
      abstract: true
      template: "<div ui-view>"
    .state 'salons.edit.special_offers.list',
      url: ''
      controller: 'App.salons.specialOffers.ListCtrl'
      controllerAs: 'list_ctrl'
      template: require('./templates/list.pug')()
      ncyBreadcrumb:
        label: 'Special Offers'
    .state 'salons.edit.special_offers.new',
      url: '/new'
      controller: 'App.salons.specialOffers.NewCtrl'
      controllerAs: 'new_ctrl'
      template: require('./templates/new.pug')()
      ncyBreadcrumb:
        label: 'New Special Offer'
        parent: 'salons.edit.special_offers.list'

    .state 'salons.edit.special_offers.edit',
      url: '/:specialOfferId/edit'
      controller: 'App.salons.specialOffers.EditCtrl'
      controllerAs: 'edit_ctrl'
      template: require('./templates/edit.pug')()
      ncyBreadcrumb:
        label: 'Edit Special Offer'
        parent: 'salons.edit.special_offers.list'

)
