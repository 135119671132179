_ = require('lodash')
TOAST_NOTIFICATION = require('app-constants').TOAST_NOTIFICATION

module.exports = class ListCtrl
  constructor: (ToastService, PromptService) ->
    'ngInject'
    @ToastService = ToastService
    @PromptService = PromptService

  $onInit: ->
    @breaks = @scheduleBreakService.getList().$object

  delete: (scheduleBreak) ->
    @PromptService.show(
      textContent: "Are you sure you want to delete schedule break?"
    ).then =>
      scheduleBreak.remove()
      .then =>
        _.remove(@breaks, scheduleBreak)
        @ToastService.success(TOAST_NOTIFICATION.deleted_successfully)
      .catch (response) =>
        message = response.data.error
        @ToastService.error(message)
